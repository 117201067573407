import React, { Component } from "react";
import { logout } from "../../../actions/AppActions";
import { MdReply, MdVisibility } from "react-icons/md";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { BsCircleFill } from "react-icons/bs";
import Tooltip from "react-tooltip";
import { NAME, URL, URLopen } from "../../../variables";
import { CircularProgress } from "@mui/material";

export class ViewRelacionado extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      path: "/view",
      loading_screen: true,
      situation: "",
      user: [],
      loading_reenviar: false,
      email_reenviado: false,

      logs: [],
    };
  }

  componentDidMount() {
    this.get_user_related(this.props.match.params.id);
  }

  get_user_related(id) {
    this.setState({ loading_screen: true });
    fetch(`${URLopen}api/auth/blueen/relationship/show`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        connection: NAME,
        user_id: id
      }),
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          if (resp.message == "Unauthenticated.") {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            this.props.logout();
            return;
          }
          if (resp.errors != null || resp.error != null) {
            let errors = Object.values(resp.errors);
            let erro = "";
            for (let i = 0; i < errors.length; i++) {
              if (i != errors.length - 1) erro += errors[i] + "\n";
              else erro += errors[i];
            }
            console.log(erro);
            this.setState({ redirect: true });
          } else {
            this.setState({
              situation: resp.user.status,
              user: resp.user,
              loading_screen: false
            });
          }
        } catch (err) {
          this.setState({ redirect: true });
          console.log(err);
        }
      })
      .catch((err) => {
        this.setState({ redirect: true });

        console.log(err);
      });
  }

  handleStatus() {
    if (this.state.situation === "Ativo") {
      return <BsCircleFill className="mx-1" color="#73B054" size={"1.25vh"} />;
    }
    if (this.state.situation === "Inativo") {
      return <BsCircleFill className="mx-1" color="#dc3545" size={"1.25vh"} />;
    }
    if (this.state.situation === "Pendente") {
      return <BsCircleFill className="mx-1" color="#ffc107" size={"1.25vh"} />;
    }
  }

  resendEmail() {
    this.setState({
      loading_reenviar: true,
    });

    fetch(`${URLopen}api/auth/blueen/relationship/resend_invitation/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: this.state.user.id,
        connection: NAME,
        organization_user_id: this.props.user.id,
        organization_user_nome: this.props.user.name,
        organization_user_email: this.props.user.email,
      }),
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          console.log(resp);
          this.setState({
            loading_reenviar: false,
            email_reenviado: true,
          });
        } catch (err) {
          this.setState({
            loading_reenviar: false,
          });
          console.log(err);
        }
      })
      .catch((err) => {
        this.setState({
          loading_reenviar: false,
        });
        console.log(err);
      });
  }

  render() {
    return (
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <h4
                className="mt-0 header-title"
                style={{ fontSize: "1.5rem", color: "black" }}
              >
                Relacionamento
              </h4>
              <p style={{ fontSize: "1rem", color: "black" }}>
                Dados base do usuário relacionado - {this.state.user.name}
              </p>
              {this.state.loading_screen == false && (
                <div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group row">
                        {/* {this.state.situation === "Ativo" && (
                          <label
                            className="col-sm-12 col-form-label"
                            style={{ fontSize: "17px" }}
                          >
                            Empresa:
                            <span style={{ fontWeight: "normal" }}>
                            </span>
                          </label>
                        )} */}
                      </div>
                      <div className="form-group row">
                        <label
                          className="col-sm-12 col-form-label"
                          style={{ fontSize: "17px" }}
                        >
                          Nome:{" "}
                          <span style={{ fontWeight: "normal" }}>
                            {this.state.user.name}
                          </span>
                        </label>
                      </div>
                      <div className="form-group row">
                        <label
                          className="col-sm-12 col-form-label"
                          style={{ fontSize: "17px" }}
                        >
                          E-mail:{" "}
                          <span style={{ fontWeight: "normal" }}>
                            {this.state.user.email}
                          </span>
                        </label>
                      </div>
                      <div className="form-group row">
                        <label
                          className="col-sm-12 col-form-label"
                          style={{ fontSize: "17px" }}
                        >
                          Status:{" "}
                          <span style={{ fontWeight: "normal" }}>
                            {this.state.situation} {this.handleStatus()}
                          </span>
                        </label>
                      </div>
                      {!this.state.user.verified &&
                        !this.state.email_reenviado && (
                          <div className="form-group row">
                            <div className="col-12">
                              <button
                                disabled={this.state.loading_reenviar}
                                className="btn btn-primary btn-large mt-3"
                                style={{ minWidth: 168, height: 38 }}
                                onClick={() => this.resendEmail()}
                              >
                                {this.state.loading_reenviar ? (
                                  <CircularProgress size={25} color="inherit" />
                                ) : (
                                  "Reenviar e-mail"
                                )}
                              </button>
                            </div>
                          </div>
                        )}
                      {!this.state.user.verified &&
                        this.state.email_reenviado && (
                          <div className="form-group row">
                            <div className="col-12">
                              <button
                                disabled={this.state.email_reenviado}
                                className="btn btn-primary btn-large mt-3"
                                style={{ minWidth: 168, height: 38 }}
                              >
                                Reenviado!
                              </button>
                            </div>
                          </div>
                        )}
                    </div>
                    {this.state.situation !== "Pendente" && (
                      <div className="col-6">
                        <div className="form-group row">
                          <label
                            className="col-sm-12 col-form-label"
                            style={{ fontSize: "17px" }}
                          >
                            Tipo de Empresa:{" "}
                            <span style={{ fontWeight: "normal" }}>
                              {this.state.user.department}
                            </span>
                          </label>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-sm-12 col-form-label"
                            style={{ fontSize: "17px" }}
                          >
                            Razão Social:{" "}
                            <span style={{ fontWeight: "normal" }}>
                              {this.state.user.enterprise.company_name}
                            </span>
                          </label>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-sm-12 col-form-label"
                            style={{ fontSize: "17px" }}
                          >
                            Nome Fantasia:{" "}
                            <span style={{ fontWeight: "normal" }}>
                              {this.state.user.enterprise.commercial_name}
                            </span>
                          </label>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-sm-12 col-form-label"
                            style={{ fontSize: "17px" }}
                          >
                            CEP:{" "}
                            <span style={{ fontWeight: "normal" }}>
                              {this.state.user?.enterprise.cep}
                            </span>
                          </label>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-sm-12 col-form-label"
                            style={{ fontSize: "17px" }}
                          >
                            Cidade:{" "}
                            <span style={{ fontWeight: "normal" }}>
                              {this.state.user?.enterprise.city} -{" "}
                              {this.state.user?.enterprise.state}
                            </span>
                          </label>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-sm-12 col-form-label"
                            style={{ fontSize: "17px" }}
                          >
                            Endereço:{" "}
                            <span style={{ fontWeight: "normal" }}>
                              {this.state.user?.enterprise.street},{" "}
                              {this.state.user?.enterprise.number || 'S/N'} -{" "}
                              {this.state.user?.enterprise.nbhd}
                            </span>
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                  {this.state.situation !== "Pendente" &&
                    this.state.logs.length !== 0 && (
                      <div className="table-rep-plugin mt-5">
                        <div
                          className="table-responsive mb-0"
                          data-pattern="priority-columns"
                        >
                          <h4
                            className="mt-0 header-title"
                            style={{ fontSize: "1.5rem", color: "black" }}
                          >
                            Logs do usuário
                          </h4>

                          <table
                            id="tech-companies-1"
                            className="table table-striped table-hover"
                            style={{ textAlign: "center" }}
                          >
                            <thead>
                              <tr>
                                <th>Ação</th>
                                <th>Módulo</th>
                                <th>Data</th>
                                <th>Detalhes</th>
                              </tr>
                            </thead>
                            {/* dados da lista ----------------------------------- */}

                            <tbody>
                              <tr>
                                <td>Receber Coleta</td>
                                <td>Coleta e Transporte</td>
                                <td>dd/mm/AAAA</td>
                                <td>
                                  <Link to={"/relacionamentos_blueen_open/view"}>
                                    <span
                                      className="btn btn-warning btn-outline btn-circle me-1 mb-1"
                                      data-tip="Ver"
                                    >
                                      <Tooltip />
                                      <MdVisibility
                                        style={{ color: "white" }}
                                      />
                                    </span>
                                  </Link>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                </div>
              )}
              {this.state.loading_screen == false && (
                <div className="row mt-4">
                  <div
                    className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                    style={{ marginBottom: "0.5rem" }}
                  >
                    <Link to="/relacionamentos_blueen_open">
                      {" "}
                      <span className="btn btn-secondary btn-lg waves-effect waves-light">
                        <MdReply /> Voltar
                      </span>
                    </Link>
                  </div>
                </div>
              )}
              {this.state.loading_screen == true && (
                <div className="row">
                  {" "}
                  <div
                    className="col-12 d-flex justify-content-center align-items-center"
                    style={{ marginTop: "2rem" }}
                  >
                    <div
                      className="spinner-border text-primary"
                      role="status"
                    ></div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapsStateToProps = (state) => ({
  token: state.AppReducer.token,
  user: state.AppReducer.user,
});

export default connect(mapsStateToProps, { logout })(ViewRelacionado);
