import React, { Component } from "react";
import { Redirect, Link, useHistory } from "react-router-dom";
import { logout } from "../../../actions/AppActions";
import { connect } from "react-redux";

// users
import { NAME, URL, URLopen } from "../../../variables";
import { maskNumberBr, maskPrice } from "../../../Auxiliar/Masks";

import { MdReply } from "react-icons/md";
import { FaRegCheckCircle, FaTrash } from "react-icons/fa";
import "jspdf-autotable";
import SweetAlert from "react-bootstrap-sweetalert";
import { NumericFormat } from "react-number-format";
import TransportadoraModal from "../../Modais/TransportadoraModal";
import MotoristaModal from "../../Modais/MotoristaModal";
import VeiculoModal from "../../Modais/VeiculoModal";
import { BsCircleFill } from "react-icons/bs";
import FileInput from "../../../Auxiliar/FileInput";
import GetToken from "../../SigorRequests/GetToken";

class CriarDestinacaoInterna extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      path: "/destinacao_interna",
      loading_screen: false,

      Destinos: [],
      Estoques: [],
      AllEstoques: [],
      EstoqueInterno: [],
      AllMateriais: [],
      Materiais: [],
      MateriaisInseridos: [],
      Transportadoras: [],
      Veiculos: [],
      ResiduosRejeitos: [],
      Motoristas: [],
      Destinacoes: [],
      Tratamentos: [],

      DestinoSelecionado: {},
      EstoqueSelecionado: {},
      Materialselecionado: {},

      Tratamento: "",
      UnidadePeso: "",
      UnidadeVolume: "",
      Peso_kg: "",
      Volume_lt: "",
      Movimentacao: "",
      ValorFinanceiro: "",
      DestinacaoId: "",
      Descricao: "",
      ChaveNota: "",
      NumeroNota: "",
      SerieNota: "",
      pendenciaFiscal: false,
      AnexoNota: null,
      NameAnexo: null,
      genMtr: false,

      pdf: '',
      Outros: false,
      TransportadoraId: '',
      VeiculoId: '',
      MotoristaId: '',
      PesoTotal: '0',
      VolumeTotal: '0',
      ReceitaTotal: '0.00',
      DespesaTotal: '0.00',
      Saldo: '0.00',
      Situacao: 'Rascunho',
      DataAgendamento: '',
      DataRecebido: '',
      DataColeta: '',
      DataAtual: '',
      DataCriacao: '',
      Peso: '',
      Volume: '',
      MaterialId: '',
      Destino: '',
      EstoqueId: '',
      ResponsavelId: '',
      DestinacaoInternaId: '',
      loading_save: false,
      msgErro: '',
      required: false,
      validated: false,
      show_warning: false,
      show_warning_inserir: false,
      show_warning_internal_relationship: false,
      redirect_solicitar_coleta: false,
      redirectView: false,
      warning: true,
      image: '',
      GenerateCollectOpen: false,
    };
  }

  get_data() {
    if (this.state.loading_screen == false) {
      this.setState({ loading_screen: true });
    }
    fetch(`${URL}api/get_dados_destinacao_interna`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${this.props.token}`,
        // 'Content-Type': 'application/json',
      },
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          console.log(resp);
          if (resp.message == "Unauthenticated.") {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            // this.setState({redirect:true,path:'/'});
            this.props.logout();
            return;
          }
          if (resp.errors != null || resp.error != null) {
            let errors = Object.values(resp.errors);
            let erro = "";
            for (let i = 0; i < errors.length; i++) {
              if (i != errors.length - 1) erro += errors[i] + "\n";
              else erro += errors[i];
            }
            console.log(erro);
            this.setState({ redirect: true });
          } else {
            this.setState({
              loading_screen: false,
              Destinos: resp.destinos,
              EstoqueInterno: resp.estoque_interno,
              AllEstoques: resp.estoques,
              Destinacoes: resp.destinacoes,
              ResiduosRejeitos: resp.materiais,
              Tratamentos: resp.tratamentos,
              Transportadoras: resp.transportadoras,
              Veiculos: resp.veiculos,
              Motoristas: resp.motoristas,
            });
          }
        } catch (err) {
          this.setState({ redirect: true });
          console.log(err);
        }
      })
      .catch((err) => {
        this.setState({ redirect: true });

        console.log(err);
      });
  }

  isNull(elemente) {
    if (elemente == null) {
      return "";
    } else {
      return elemente;
    }
  }

  async coletaOpen(destinacaoId) {
    try {
      const response = await fetch(`${URLopen}api/auth/blueen/solicitation/store`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          //* Flags / IDs
          blueen_destino_id: this.state.Destino,
          blueen_destinacao_id: destinacaoId,
          blueen_responsible_id: this.props.user.id,
          connection: NAME,

          //* Collect data
          started_date: this.state.DataAgendamento,
          appointment_date: this.state.DataAgendamento,
          receiving_date: this.state.DataRecebido,
          collect_date: this.state.DataColeta,
          finalization_date: this.state.DataFinalizado,
          total_weight: this.state.PesoTotal,
          total_volume: this.state.VolumeTotal,
          income: this.state.ReceitaTotal,
          expend: this.state.DespesaTotal,
          balance: this.state.Saldo,
          invoice_number: this.state.NumeroNota,
          invoice_series: this.state.SerieNota,
          invoice_key: this.state.ChaveNota,
          invoice_pendency: this.state.pendenciaFiscal,

          wastes_rejects: this.state.MateriaisInseridos,
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        this.setState({
          loading_save: false,
          msgErro: `Erro ao abrir a coleta: ${errorData.message || response.statusText}`,
          redirectView: true,
        });
      }

      // Se a resposta for bem-sucedida
      const data = await response.json();
      console.log("Coleta gerada com sucesso:", data);
    } catch (error) {
      this.setState({
        loading_save: false,
        msgErro: "Ocorreu um erro ao tentar gerar a coleta. Por favor, tente novamente.",
        redirectView: true,
      });
    }
  }

  salvar() {
    if (this.state.Materialselecionado.length > 0) {
      this.setState({
        show_warning_inserir: true,
        loading_save: true,
        msgErro: "",
      });
    } else {
      this.setState({
        show_warning_inserir: false,
        loading_save: true,
        msgErro: "",
      });
      fetch(`${URL}api/store_destinacao_interna`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.props.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          estoque_id: this.state.EstoqueId,
          destino_id: this.state.Destino,
          data_criacao: this.state.DataAtual,
          data_agendamento: this.state.DataAgendamento,
          data_recebimento: this.state.DataRecebido,
          data_coleta: this.state.DataColeta,
          peso_total: this.state.PesoTotal,
          volume_total: this.state.VolumeTotal,
          receita_total: this.state.ReceitaTotal,
          despesa_total: this.state.DespesaTotal,
          saldo: this.state.Saldo,
          motorista_id: this.state.MotoristaId,
          veiculo_id: this.state.VeiculoId,
          descricao: this.state.Descricao,
          situacao: this.state.Situacao,
          transportadora_id:
            this.state.TransportadoraId === "proprio"
              ? ""
              : this.state.TransportadoraId ?? null,
          materiais: this.state.MateriaisInseridos,
          key: this.state.pendenciaFiscal === true ? "" : this.state.ChaveNota,
          number:
            this.state.pendenciaFiscal === true ? "" : this.state.NumeroNota,
          serie:
            this.state.pendenciaFiscal === true ? "" : this.state.SerieNota,
          document:
            this.state.pendenciaFiscal === true ? "" : this.state.AnexoNota,
          pendencia_fiscal: this.state.pendenciaFiscal,
          responsavel_id: this.state.ResponsavelId,
          data_entrada: new Date(),
          connection: NAME,
          gen_mtr: this.state.genMtr,
        }),
      })
        .then(async (responseLog) => {
          try {
            let resp = await responseLog.json();
            if (resp.errors != null || resp.error != null) {
              let errors = Object.values(resp.errors);
              let erro = "";
              for (let i = 0; i < errors.length; i++) {
                if (i != errors.length - 1) erro += errors[i] + "\n";
                else erro += errors[i];
              }
              console.log(erro);
              window.scrollTo(500, 0);
              this.setState({ loading_save: false, msgErro: erro });
            } else {
              if (this.state.GenerateCollectOpen) {
                this.setState({ DestinacaoInternaId: resp.data });
                this.coletaOpen(resp.data)
              }

              this.setState({ loading_save: false, redirect: true });
            }
          } catch (err) {
            console.log(err);
            this.setState({
              loading_save: false,
              msgErro: "Erro ao pegar resposta do servidor",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            loading_save: false,
            msgErro:
              "Erro ao pegar resposta do servidor. Você está conectado a internet?",
          });
        });
    }
  }

  componentDidMount() {
    let data = new Date();
    let dia = String(data.getDate()).padStart(2, "0");
    let mes = String(data.getMonth() + 1).padStart(2, "0");
    let ano = data.getFullYear();
    this.setState({
      DataAtual: ano + "-" + mes + "-" + dia,
    });
    this.get_data();

    const imageUrl =
      "https://upload.wikimedia.org/wikipedia/commons/thumb/4/47/PNG_transparency_demonstration_1.png/320px-PNG_transparency_demonstration_1.png";

    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.setState({ image: reader.result });
        };
        reader.readAsDataURL(blob);
      });
  }

  updateState = (item, data, item_id, id) => {
    this.setState((prevState) => ({
      [item]: [...prevState[item], { ...data }],
      [item_id]: id,
    }));
  };

  handleStatusChange = () => {
    const { DataRecebido, DataAgendamento, DataColeta } = this.state;
    if (DataRecebido) {
      this.state.Situacao !== "Recebido" &&
        this.setState({ Situacao: "Recebido" });
      return (
        <span>
          Recebido
          <BsCircleFill className="mx-1" color="#2E8EB8" size={"1.25vh"} />
        </span>
      );
    }
    if (DataColeta) {
      this.state.Situacao !== "Coletado" &&
        this.setState({ Situacao: "Coletado" });
      return (
        <span>
          Coletado
          <BsCircleFill className="mx-1" color="#2E8EB8" size={"1.25vh"} />
        </span>
      );
    }
    if (DataAgendamento) {
      this.state.Situacao !== "Agendado" &&
        this.setState({ Situacao: "Agendado" });
      return (
        <span>
          Agendado
          <BsCircleFill className="mx-1" color="#ffc107" size={"1.25vh"} />
        </span>
      );
    }
    if (!DataRecebido && !DataColeta && !DataAgendamento) {
      this.state.Situacao !== "Rascunho" &&
        this.setState({ Situacao: "Rascunho" });
      return (
        <span>
          Rascunho
          <BsCircleFill className="mx-1" color="#dc3545" size={"1.25vh"} />
        </span>
      );
    }
  };

  handleMtrChange = async () => {
    this.setState({
      genMtr: !this.state.genMtr,
      Solicitacao: "",
      MaterialSolicitacao: "",
      Materialselecionado: [],
      sigorCodigos: [],
      loadingSigor: true,
    });
    let token = this.state.sigorToken;

    if (!this.state.sigorToken) {
      this.setState({ loadingSigor: true });
      token = await GetToken({ token: this.props.token });
      this.setState({ sigorToken: token, loadingSigor: false });
    }
  };

  handleSolicitarColetaOpen = () => {
    this.setState({ GenerateCollectOpen: true });
  }

  // Function to handle changes in date inputs
  handleDateChange = ({ target }) => {
    // Extracting the name and value of the input that triggered the event
    const { name, value } = target;
    // Extracting current values for all date inputs from the state object
    const { DataRecebido, DataAgendamento, DataFinalizado, DataColeta } =
      this.state;

    // Switch statement to handle different date inputs
    switch (name) {
      case "DataAgendamento":
        // Setting the Situacao state to 'Agendado' and updating DataAgendamento with the new value
        if (value !== "") {
          this.setState({ DataAgendamento: value });
        }
        break;

      case "DataColeta":
        // Setting the Situacao state to 'Iniciado' and updating DataColeta with the new value
        if (value !== "") {
          this.setState({
            DataColeta: value,
            DataRecebido: "",
            DataFinalizado: "",
          });
        }
        break;

      case "DataRecebido":
        // Setting the Situacao state to 'Recebido' and updating DataRecebido with the new value
        if (value !== "") {
          this.setState({ DataRecebido: value, DataFinalizado: "" });
        }
        break;

      case "DataFinalizado":
        // Setting the Situacao state to 'Finalizado' and updating DataFinalizado with the new value
        if (value !== "") {
          this.setState({ DataFinalizado: value });
        }
        break;

      default:
        break;
    }
  };

  render() {
    return (
      <div className="row " >
        {this.state.redirect == true && <Redirect to={this.state.path} />}
        {this.state.redirectView == true && <Redirect to={this.state.path + "/view/" + this.state.DestinacaoInternaId} />}
        {this.state.redirect_solicitar_coleta === true && <Redirect to={this.state.path + "/solicitar_coleta/" + this.state.Destino} />}
        {this.state.collect}

        <div className="col-12">
          <div className="card">
            <div className="card-body" id="card">
              {/* <table id='table'>
                                <thead>
                                    <tr>
                                        <th>Teste tabela</th>
                                        <th>img</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>texto coluna um</td>
                                        <td><img src={this.state.image} alt="Image" /></td>
                                    </tr>
                                </tbody>
                            </table> */}

              <h4
                className="mt-0 header-title"
                style={{ fontSize: "1.5rem", color: "black" }}
              >
                Cadastrar destinação
              </h4>
              <p style={{ fontSize: "1rem", color: "black" }}>
                Cadastre uma nova destinação
              </p>
              <br />

              {this.state.loading_screen == false && <div>
                {this.state.msgErro != '' && <div>
                  <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>
                    <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                  </div>
                  <br />

                </div>}
                <div className="row">
                  <div className="col-12">
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">Destino<b style={{ color: 'red' }}>*</b></label>
                      <div className="col-sm-12">
                        <select value={this.state.Destino} className="form-control"
                          onChange={(e) => {
                            if (e.target.value == '') {
                              this.setState({
                                Estoques: [],
                                Destino: e.target.value,
                                DestinoSelecionado: {},
                                EstoqueId: '',
                                Materiais: [],
                                MaterialId: '',
                                Volume: '',
                                Peso: '',
                                Movimentacao: '',
                                Outros: false,
                                ValorFinanceiro: '',
                                DestinacaoId: '',
                                required: false,
                                validated: false,
                                PesoTotal: '0',
                                VolumeTotal: '0',
                                DespesaTotal: '0.00',
                                ReceitaTotal: '0.00',
                                Saldo: '0.00',
                                Situacao: 'Rascunho',
                                Materialselecionado: {},
                                MateriaisInseridos: [],
                                Tratamento: '',
                                GenerateCollectOpen: false
                              });
                            } else {
                              let destino = this.state.Destinos.filter(item => item.id == e.target.value);


                              if (destino[0].isInternalRelationship) {
                                this.setState({ show_warning_internal_relationship: true })
                              }

                              let estoques = [];

                              for (
                                let i = 0;
                                i < this.state.AllEstoques.length;
                                i++
                              ) {
                                for (
                                  let j = 0;
                                  j <
                                  this.state.AllEstoques[i].residuos_rejeitos
                                    .length;
                                  j++
                                ) {
                                  for (
                                    let k = 0;
                                    k < destino[0].residuos_rejeitos.length;
                                    k++
                                  ) {
                                    if (
                                      destino[0].residuos_rejeitos[k].id ==
                                      this.state.AllEstoques[i]
                                        .residuos_rejeitos[j].id
                                    ) {
                                      estoques.push(
                                        this.state.AllEstoques[i]
                                      );
                                      k = destino[0].residuos_rejeitos.length;
                                      j =
                                        this.state.AllEstoques[i]
                                          .residuos_rejeitos.length;
                                    }
                                  }
                                }
                              }

                              let estoque_filtro = [];
                              for (let i = 0; i < estoques.length; i++) {
                                for (
                                  let j = 0;
                                  j < this.state.EstoqueInterno.length;
                                  j++
                                ) {
                                  if (
                                    estoques[i].id ==
                                    this.state.EstoqueInterno[j].estoque_id
                                  ) {
                                    estoque_filtro.push(estoques[i]);
                                    j = this.state.EstoqueInterno.length;
                                  }
                                }
                              }

                              this.setState({
                                Estoques: estoques,
                                Destino: e.target.value,
                                DestinoSelecionado: destino,
                                ResponsavelId: destino[0].user_id.id,
                                EstoqueId: "",
                                Materiais: [],
                                MaterialId: "",
                                Volume: "",
                                Peso: "",
                                Movimentacao: "",
                                Outros: false,
                                ValorFinanceiro: "",
                                DestinacaoId: "",
                                required: false,
                                validated: false,
                                PesoTotal: "0",
                                VolumeTotal: "0",
                                DespesaTotal: "0.00",
                                ReceitaTotal: "0.00",
                                Saldo: "0.00",
                                Materialselecionado: {},
                                MateriaisInseridos: [],
                                Tratamento: "",
                                GenerateCollectOpen: false
                              });
                            }
                          }}
                        >
                          <option value={""}>Selecione um destino</option>
                          {this.state.Destinos.map((item, id) => (
                            <option key={id} value={item.id}>
                              {item.fantasia}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    {this.state.Estoques.length > 0 && (
                      <>
                        <hr />
                        <label
                          className="col-sm-12 col-form-label"
                          style={{ color: "black", fontSize: "1.5rem" }}
                        >
                          Dados do Estoque:
                        </label>
                        <div className="form-group row">
                          <label className="col-sm-12 col-form-label">
                            Estoque<b style={{ color: "red" }}>*</b>
                          </label>
                          <div className="col-sm-12">
                            <select
                              value={this.state.EstoqueId}
                              className="form-control"
                              onChange={(e) => {
                                if (e.target.value == "") {
                                  this.setState({
                                    EstoqueId: e.target.value,
                                    EstoqueSelecionado: {},
                                    Materiais: [],
                                    MaterialId: "",
                                    Volume: "",
                                    Peso: "",
                                    PesoTotal: "0",
                                    VolumeTotal: "0",
                                    Movimentacao: "",
                                    Outros: false,
                                    ValorFinanceiro: "",
                                    DespesaTotal: "0.00",
                                    ReceitaTotal: "0.00",
                                    Saldo: "0.00",
                                    DestinacaoId: "",
                                    required: false,
                                    validated: false,
                                    Tratamento: "",
                                    Materialselecionado: {},
                                    MateriaisInseridos: [],
                                  });
                                } else {
                                  let estoque = this.state.Estoques.filter(
                                    (item) => item.id == e.target.value
                                  );
                                  let materiais = [];
                                  for (
                                    let i = 0;
                                    i <
                                    this.state.DestinoSelecionado[0]
                                      .residuos_rejeitos.length;
                                    i++
                                  ) {
                                    for (
                                      let j = 0;
                                      j < estoque[0].residuos_rejeitos.length;
                                      j++
                                    ) {
                                      if (
                                        this.state.DestinoSelecionado[0]
                                          .residuos_rejeitos[i].id ==
                                        estoque[0].residuos_rejeitos[j].id
                                      ) {
                                        materiais.push(
                                          estoque[0].residuos_rejeitos[j]
                                        );
                                        break;
                                      }
                                    }
                                  }

                                  let mat_filtro = [];
                                  for (let i = 0; i < materiais.length; i++) {
                                    for (
                                      let j = 0;
                                      j < this.state.EstoqueInterno.length;
                                      j++
                                    ) {
                                      if (
                                        materiais[i].id ==
                                        this.state.EstoqueInterno[j]
                                          .material_id &&
                                        this.state.EstoqueInterno[j]
                                          .peso_disponivel != 0 &&
                                        this.state.EstoqueInterno[j]
                                          .estoque_id == e.target.value
                                      ) {
                                        mat_filtro.push(materiais[i]);
                                        j = this.state.EstoqueInterno.length;
                                      }
                                    }
                                  }
                                  console.log(estoque);
                                  //If need to change the material list with filtred materials, just change the value of materiais to mat_filtro
                                  this.setState({
                                    EstoqueId: e.target.value,
                                    Materiais: materiais,
                                    MaterialId: "",
                                    Volume: "",
                                    Peso: "",
                                    PesoTotal: "0",
                                    VolumeTotal: "0",
                                    Movimentacao: "",
                                    Outros: false,
                                    ValorFinanceiro: "",
                                    DespesaTotal: "0.00",
                                    ReceitaTotal: "0.00",
                                    Saldo: "0.00",
                                    DestinacaoId: "",
                                    required: false,
                                    validated: false,
                                    Tratamento: "",
                                    Materialselecionado: {},
                                    MateriaisInseridos: [],
                                  });
                                }
                              }}
                            >
                              <option value={""}>Selecione uma opção</option>
                              {this.state.Estoques.map((item, id) => (
                                <option key={id} value={item.id}>
                                  {" "}
                                  {item.nome_fantasia}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <br />

                        {this.state.Materiais.length > 0 && (
                          <>
                            <div className="form-group row">
                              <label className="col-sm-12 col-form-label">
                                Material<b style={{ color: "red" }}>*</b>
                              </label>
                              <div className="col-sm-12">
                                <select
                                  value={this.state.MaterialId}
                                  className="form-control"
                                  onChange={(e) => {
                                    let duplicated = false;
                                    for (
                                      let i = 0;
                                      i <
                                      this.state.MateriaisInseridos.length;
                                      i++
                                    ) {
                                      console.log(
                                        this.state.MateriaisInseridos[i]
                                      );
                                      if (
                                        e.target.value ==
                                        this.state.MateriaisInseridos[i]
                                          .residuo_rejeito_id
                                      ) {
                                        duplicated = true;
                                      }
                                    }
                                    if (duplicated == true) {
                                      this.setState({
                                        MaterialId: "",
                                        Materialselecionado: {},
                                        Movimentacao: "",
                                        Outros: false,
                                        ValorFinanceiro: "",
                                        DestinacaoId: "",
                                        Tratamento: "",
                                        Volume: "",
                                        Peso: "",
                                        required: false,
                                      });
                                    } else if (e.target.value == "") {
                                      this.setState({
                                        MaterialId: e.target.value,
                                        Materialselecionado: {},
                                        Movimentacao: "",
                                        Outros: false,
                                        ValorFinanceiro: "",
                                        DestinacaoId: "",
                                        Tratamento: "",
                                        Volume: "",
                                        Peso: "",
                                        required: false,
                                      });
                                    } else {
                                      let estoque_selecionado;
                                      let hasRegister;

                                      estoque_selecionado =
                                        this.state.EstoqueInterno.filter(
                                          (item) =>
                                            item.estoque_id ===
                                            this.state.EstoqueId &&
                                            item.material_id ===
                                            e.target.value
                                        );

                                      if (estoque_selecionado.length > 0) {
                                        hasRegister = true;
                                        console.log("possui estoque");
                                      } else {
                                        estoque_selecionado =
                                          this.state.Estoques.filter(
                                            (item) =>
                                              item.id === this.state.EstoqueId
                                          );
                                        hasRegister = false;
                                        console.log("não possui estoque");
                                      }

                                      let material_selecionado =
                                        this.state.Materiais.filter(
                                          (item) => item.id == e.target.value
                                        );
                                      let valor = parseFloat(
                                        material_selecionado[0]
                                          .valor_saldo_financeiro
                                      );
                                      console.log(
                                        "estoque",
                                        estoque_selecionado,
                                        this.state.EstoqueInterno,
                                        this.state.Estoques
                                      );
                                      this.setState({
                                        EstoqueSelecionado:
                                          estoque_selecionado,
                                        MaterialId: e.target.value,
                                        Materialselecionado:
                                          material_selecionado,
                                        Movimentacao:
                                          material_selecionado[0]
                                            .saldo_financeiro,
                                        ValorFinanceiro: valor.toFixed(2),
                                        DestinacaoId:
                                          material_selecionado[0]
                                            .destinacao_id,
                                        Tratamento:
                                          material_selecionado[0]
                                            .tratamento_id,
                                        Volume: "",
                                        Peso: "",
                                        required: false,
                                        hasRegister,
                                      });
                                    }
                                  }}
                                >
                                  <option value={""}>
                                    Selecione uma opção
                                  </option>
                                  {this.state.Materiais.map((item, id) => (
                                    <option key={id} value={item.id}>
                                      {item.nome}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            {this.state.Materialselecionado.length > 0 && (
                              <>
                                {this.state.Materialselecionado[0]
                                  .unidade_principal.tipo == "Peso" && (
                                    <div className="row">
                                      <div className="col-md-6 col-12">
                                        <label className="col-sm-12 col-form-label">
                                          Peso<b style={{ color: "red" }}>*</b>
                                        </label>
                                        <div className="input-group mb-3">
                                          {this.state.hasRegister ? (
                                            <span
                                              className="input-group-text"
                                              id="basic-addon3"
                                            >
                                              Disponível:
                                              {maskNumberBr(
                                                this.state.EstoqueSelecionado[0]
                                                  .peso_disponivel
                                              ) +
                                                " " +
                                                this.state.EstoqueSelecionado[0]
                                                  .material.unidade_principal
                                                  .sigla}
                                            </span>
                                          ) : (
                                            <span
                                              className="input-group-text"
                                              id="basic-addon3"
                                            >
                                              Disponível: 0
                                              {
                                                this.state
                                                  .Materialselecionado[0]
                                                  .unidade_principal.sigla
                                              }
                                            </span>
                                          )}
                                          <NumericFormat
                                            className="form-control quantidade"
                                            id="peso"
                                            placeholder="Informe o peso"
                                            type="text"
                                            thousandsGroupStyle="thousand"
                                            thousandSeparator="."
                                            decimalScale={2}
                                            decimalSeparator=","
                                            valueIsNumericString
                                            value={this.state.Peso}
                                            onValueChange={(values, e) => {
                                              let mult;
                                              values.value == ""
                                                ? (mult = 0.0)
                                                : (mult = values.value);
                                              let val_total =
                                                parseFloat(
                                                  this.state
                                                    .Materialselecionado[0]
                                                    .valor_saldo_financeiro
                                                ).toFixed(2) * parseFloat(mult);
                                              this.setState({
                                                required: false,
                                                validated: false,
                                                UnidadePeso:
                                                  this.state
                                                    .Materialselecionado[0]
                                                    .unidade_principal.sigla,
                                                UnidadeVolume:
                                                  this.state
                                                    .Materialselecionado[0]
                                                    .unidade_secundaria.sigla,
                                                Peso_kg:
                                                  parseFloat(values.value) *
                                                  parseFloat(
                                                    this.state
                                                      .Materialselecionado[0]
                                                      .unidade_principal
                                                      .equivalencia
                                                  ),
                                                Volume_lt:
                                                  parseFloat(values.value) *
                                                  parseFloat(
                                                    this.state
                                                      .Materialselecionado[0]
                                                      .equivalencia
                                                  ) *
                                                  parseFloat(
                                                    this.state
                                                      .Materialselecionado[0]
                                                      .unidade_secundaria
                                                      .equivalencia
                                                  ),
                                                Peso: values.value,
                                                ValorFinanceiro:
                                                  parseFloat(val_total).toFixed(
                                                    2
                                                  ),
                                                Volume:
                                                  values.value == ""
                                                    ? ""
                                                    : parseFloat(values.value) *
                                                    parseFloat(
                                                      this.state
                                                        .Materialselecionado[0]
                                                        .equivalencia
                                                    ),
                                                warning: false,
                                              });
                                            }}
                                          />
                                          {this.state.warning &&
                                            !this.state.hasRegister && (
                                              <b
                                                style={{
                                                  color: "red",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                O material selecionado não
                                                possui entradas, ao salvar serão
                                                criados registros
                                              </b>
                                            )}
                                        </div>
                                        {this.state.required == true && (
                                          <b
                                            style={{
                                              color: "red",
                                              fontSize: "12px",
                                            }}
                                          >
                                            O campo Peso é obrigatório
                                          </b>
                                        )}
                                        {this.state.validated == true && (
                                          <b
                                            style={{
                                              color: "red",
                                              fontSize: "12px",
                                            }}
                                          >
                                            O Volume atendido não pode ser maior
                                            que o disponivel
                                          </b>
                                        )}
                                      </div>
                                      <div className="col-md-6 col-12">
                                        <label className="col-sm-12 col-form-label">
                                          Volume
                                          <b style={{ color: "red" }}>*</b>
                                        </label>
                                        <div className="input-group mb-3">
                                          {this.state.hasRegister ? (
                                            <span
                                              className="input-group-text"
                                              id="basic-addon3"
                                            >
                                              Disponível:{" "}
                                              {maskNumberBr(
                                                this.state.EstoqueSelecionado[0]
                                                  .volume_disponivel
                                              ) +
                                                " " +
                                                this.state.EstoqueSelecionado[0]
                                                  .material.unidade_secundaria
                                                  .sigla}
                                            </span>
                                          ) : (
                                            <span
                                              className="input-group-text"
                                              id="basic-addon3"
                                            >
                                              Disponível: 0
                                              {
                                                this.state
                                                  .Materialselecionado[0]
                                                  .unidade_secundaria.sigla
                                              }
                                            </span>
                                          )}
                                          <NumericFormat
                                            className="form-control"
                                            id="volume"
                                            disabled
                                            placeholder="Informe o volume"
                                            type="text"
                                            thousandsGroupStyle="thousand"
                                            thousandSeparator="."
                                            decimalScale={2}
                                            decimalSeparator=","
                                            valueIsNumericString
                                            value={this.state.Volume}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                {this.state.Materialselecionado[0]
                                  .unidade_principal.tipo !== "Peso" && (
                                    <div className="row">
                                      <div className="col-md-6 col-12">
                                        <label className="col-sm-12 col-form-label">
                                          {
                                            this.state.Materialselecionado[0]
                                              .unidade_principal.tipo
                                          }
                                          <b style={{ color: "red" }}>*</b>
                                        </label>
                                        <div className="input-group mb-3">
                                          {this.state.hasRegister ? (
                                            <span
                                              className="input-group-text"
                                              id="basic-addon3"
                                            >
                                              Disponível:{" "}
                                              {maskNumberBr(
                                                this.state.EstoqueSelecionado[0]
                                                  .volume_disponivel
                                              ) +
                                                " " +
                                                this.state.EstoqueSelecionado[0]
                                                  .material.unidade_principal
                                                  .sigla}
                                            </span>
                                          ) : (
                                            <span
                                              className="input-group-text"
                                              id="basic-addon3"
                                            >
                                              Disponível: 0
                                              {
                                                this.state
                                                  .Materialselecionado[0]
                                                  .unidade_principal.sigla
                                              }
                                            </span>
                                          )}
                                          <NumericFormat
                                            type="text"
                                            className="form-control quantidade"
                                            id="volume"
                                            thousandsGroupStyle="thousand"
                                            thousandSeparator="."
                                            decimalScale={2}
                                            decimalSeparator=","
                                            valueIsNumericString
                                            placeholder={`${this.state.Materialselecionado[0].unidade_principal.tipo === "Volume" ? "Informe o" : "Informe a"} ${this.state.Materialselecionado[0].unidade_principal.tipo}`}
                                            onValueChange={(values, e) => {
                                              let mult;
                                              values.value == ""
                                                ? (mult = 0.0)
                                                : (mult = values.value);
                                              let val_total =
                                                parseFloat(
                                                  this.state
                                                    .Materialselecionado[0]
                                                    .valor_saldo_financeiro
                                                ).toFixed(2) * parseFloat(mult);
                                              this.setState({
                                                required: false,
                                                validated: false,
                                                UnidadePeso:
                                                  this.state
                                                    .Materialselecionado[0]
                                                    .unidade_secundaria.sigla,
                                                UnidadeVolume:
                                                  this.state
                                                    .Materialselecionado[0]
                                                    .unidade_principal.sigla,
                                                Volume_lt:
                                                  parseFloat(values.value) *
                                                  parseFloat(
                                                    this.state
                                                      .Materialselecionado[0]
                                                      .unidade_principal
                                                      .equivalencia
                                                  ),
                                                Peso_kg:
                                                  parseFloat(values.value) *
                                                  parseFloat(
                                                    this.state
                                                      .Materialselecionado[0]
                                                      .equivalencia
                                                  ) *
                                                  parseFloat(
                                                    this.state
                                                      .Materialselecionado[0]
                                                      .unidade_secundaria
                                                      .equivalencia
                                                  ),
                                                Volume: values.value,
                                                ValorFinanceiro:
                                                  parseFloat(val_total).toFixed(
                                                    2
                                                  ),
                                                Peso:
                                                  values.value == ""
                                                    ? ""
                                                    : parseFloat(values.value) *
                                                    parseFloat(
                                                      this.state
                                                        .Materialselecionado[0]
                                                        .equivalencia
                                                    ),
                                              });
                                            }}
                                            value={this.state.Volume}
                                          />
                                        </div>
                                        {this.state.required == true && (
                                          <b
                                            style={{
                                              color: "red",
                                              fontSize: "12px",
                                            }}
                                          >
                                            O campo Volume é obrigatório
                                          </b>
                                        )}
                                        {this.state.validated == true && (
                                          <b
                                            style={{
                                              color: "red",
                                              fontSize: "12px",
                                            }}
                                          >
                                            O Volume atendido não pode ser maior
                                            que o disponivel
                                          </b>
                                        )}
                                      </div>
                                      <div className="col-md-6 col-12">
                                        <label className="col-sm-12 col-form-label">
                                          Peso<b style={{ color: "red" }}>*</b>
                                        </label>
                                        <div className="input-group mb-3">
                                          {this.state.hasRegister ? (
                                            <span
                                              className="input-group-text"
                                              id="basic-addon3"
                                            >
                                              Disponível:{" "}
                                              {maskNumberBr(
                                                this.state.EstoqueSelecionado[0]
                                                  .peso_disponivel
                                              ) +
                                                " " +
                                                this.state.EstoqueSelecionado[0]
                                                  .material.unidade_secundaria
                                                  .sigla}
                                            </span>
                                          ) : (
                                            <span
                                              className="input-group-text"
                                              id="basic-addon3"
                                            >
                                              Disponível: 0
                                              {
                                                this.state
                                                  .Materialselecionado[0]
                                                  .unidade_secundaria.sigla
                                              }
                                            </span>
                                          )}
                                          <NumericFormat
                                            type="text"
                                            className="form-control"
                                            id="peso"
                                            disabled
                                            valueIsNumericString
                                            thousandsGroupStyle="thousand"
                                            thousandSeparator="."
                                            decimalScale={2}
                                            decimalSeparator=","
                                            placeholder="Informe o peso"
                                            onValueChange={(values, e) => {
                                              this.setState({
                                                Peso: values.value,
                                              });
                                            }}
                                            value={this.state.Peso}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                <div className="row">
                                  <div className="col-md-6 col-12">
                                    <label className="col-sm-12 col-form-label">
                                      Tipo de movimentação
                                      <b style={{ color: "red" }}>*</b>
                                    </label>
                                    <div className="input-group mb-3">
                                      <select
                                        value={this.state.Movimentacao}
                                        className="form-control"
                                        onChange={(e) => {
                                          if (e.target.value == "Outros") {
                                            this.setState({
                                              Outros: true,
                                              Movimentacao: "Não Aplicável",
                                            });
                                          } else {
                                            this.setState({
                                              Movimentacao: e.target.value,
                                              Outros: false,
                                            });
                                          }
                                        }}
                                      >
                                        <option value={"Outros"}>
                                          Outros
                                        </option>
                                        <option value={"Receita"}>
                                          Receita
                                        </option>
                                        <option value={"Despesa"}>
                                          Despesa
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                  {this.state.Outros == true && (
                                    <div className="col-md-6 col-12">
                                      <label className="col-sm-12 col-form-label">
                                        Outros
                                        <b style={{ color: "red" }}>*</b>
                                      </label>
                                      <div className="input-group mb-3">
                                        <select
                                          value={this.state.Movimentacao}
                                          className="form-control"
                                          onChange={(e) => {
                                            this.setState({
                                              Movimentacao: e.target.value,
                                            });
                                          }}
                                        >
                                          <option value={"Não Aplicável"}>
                                            Não Aplicável
                                          </option>
                                          <option value={"Doação"}>
                                            Doação
                                          </option>
                                          <option value={"Despesa Evitada"}>
                                            Despesa Evitada
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                  )}
                                  {this.state.Outros == false && (
                                    <div className="col-md-6 col-12">
                                      <label className="col-sm-12 col-form-label">
                                        Valor financeiro
                                        <b style={{ color: "red" }}>*</b>
                                      </label>
                                      <div className="input-group mb-3">
                                        <NumericFormat
                                          className="form-control"
                                          placeholder="R$ 99,99"
                                          type="text"
                                          prefix={"R$ "}
                                          thousandsGroupStyle="thousand"
                                          thousandSeparator="."
                                          decimalScale={2}
                                          decimalSeparator=","
                                          valueIsNumericString
                                          onValueChange={(values, e) => {
                                            this.setState({
                                              ValorFinanceiro: values.value,
                                            });
                                          }}
                                          value={this.state.ValorFinanceiro}
                                        />
                                        {/* <input className="form-control" placeholder="R$ 99,99" type="text" value={maskPrice(this.state.ValorFinanceiro)} onChange={(e) => console.log((e.target.value))} /> */}
                                      </div>
                                    </div>
                                  )}
                                  {this.state.Outros == true && (
                                    <div className="col-md-6 col-12">
                                      <label className="col-sm-12 col-form-label">
                                        Valor financeiro
                                        <b style={{ color: "red" }}>*</b>
                                      </label>
                                      <div className="input-group mb-3">
                                        <NumericFormat
                                          className="form-control"
                                          disabled
                                          placeholder="R$ 99,99"
                                          type="text"
                                          prefix={"R$ "}
                                          thousandsGroupStyle="thousand"
                                          thousandSeparator="."
                                          decimalScale={2}
                                          decimalSeparator=","
                                          valueIsNumericString
                                          value={maskPrice("0.00")}
                                          onValueChange={(values, e) =>
                                            this.setState({
                                              ValorFinanceiro: values.value,
                                            })
                                          }
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>

                                <div className="row">
                                  <div className="col-md-6 col-12">
                                    <label className="col-sm-12 col-form-label">
                                      Destinação
                                      <b style={{ color: "red" }}>*</b>
                                    </label>
                                    <div className="input-group mb-3">
                                      <select
                                        value={this.state.DestinacaoId}
                                        className="form-control"
                                        onChange={(e) => {
                                          this.setState({
                                            DestinacaoId: e.target.value,
                                          });
                                        }}
                                      >
                                        <option value={""}>
                                          selecione uma opção
                                        </option>
                                        {this.state.Destinacoes.map(
                                          (item, id) => (
                                            <option key={id} value={item.id}>
                                              {"[" +
                                                item.codigo +
                                                "] " +
                                                item.descricao}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-12">
                                    <label className="col-sm-12 col-form-label">
                                      Tratamento
                                      <b style={{ color: "red" }}>*</b>
                                    </label>
                                    <div className="input-group mb-3">
                                      <select
                                        value={this.state.Tratamento}
                                        className="form-control"
                                        onChange={(e) => {
                                          this.setState({
                                            Tratamento: e.target.value,
                                          });
                                        }}
                                      >
                                        <option value={""}>
                                          selecione uma opção
                                        </option>
                                        {this.state.Tratamentos.map(
                                          (item, id) => (
                                            <option key={id} value={item.id}>
                                              {"[" +
                                                item.codigo +
                                                "] " +
                                                item.descricao}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                  <button
                                    type="button"
                                    className="btn btn-success"
                                    style={{ backgorundColor: "#f2f2f2" }}
                                    onClick={() => {
                                      if (
                                        (this.state.Materialselecionado[0]
                                          .unidade_principal.tipo == "Peso" &&
                                          this.state.Peso == 0) ||
                                        (this.state.Materialselecionado[0]
                                          .unidade_principal.tipo ===
                                          "Peso" &&
                                          this.state.Peso == "")
                                      ) {
                                        this.setState({
                                          required: true,
                                        });
                                        document
                                          .getElementById("peso")
                                          .focus();
                                      } else if (
                                        (this.state.Materialselecionado[0]
                                          .unidade_principal.tipo !==
                                          "Peso" &&
                                          this.state.Volume == 0) ||
                                        (this.state.Materialselecionado[0]
                                          .unidade_principal.tipo !==
                                          "Peso" &&
                                          this.state.Volume == "")
                                      ) {
                                        this.setState({
                                          required: true,
                                        });
                                        document
                                          .getElementById("volume")
                                          .focus();
                                      }
                                      // else if (this.state.Materialselecionado[0].unidade_principal.tipo == 'Volume' && parseFloat(this.state.Volume) > parseFloat(this.state.EstoqueSelecionado[0].volume_disponivel)) {
                                      //     // this.setState({
                                      //     //     validated: true
                                      //     // })
                                      //     document.getElementById('volume').focus();
                                      // } else if (this.state.Materialselecionado[0].unidade_principal.tipo == 'Peso' && parseFloat(this.state.Peso) > parseFloat(this.state.EstoqueSelecionado[0].peso_disponivel)) {
                                      //     // this.setState({
                                      //     //     validated: true
                                      //     // })
                                      //     document.getElementById('peso').focus();
                                      // }
                                      else {
                                        let materiais =
                                          this.state.MateriaisInseridos;
                                        let novo_material = {
                                          residuo_rejeito_id:
                                            this.state.Materialselecionado[0]
                                              .id,
                                          nome: this.state
                                            .Materialselecionado[0].nome,
                                          unidade_peso:
                                            this.state.UnidadePeso,
                                          unidade_volume:
                                            this.state.UnidadeVolume,
                                          peso: this.state.Peso,
                                          volume: this.state.Volume,
                                          peso_kg: this.state.Peso_kg,
                                          volume_lt: this.state.Volume_lt,
                                          movimentacao:
                                            this.state.Movimentacao,
                                          valor: this.state.ValorFinanceiro,
                                          destinacao_disposicao_id:
                                            this.state.DestinacaoId,
                                          tratamento_id:
                                            this.state.Tratamento,

                                          //Input record data
                                          tipo: this.state
                                            .Materialselecionado[0].tipo,
                                          equivalencia:
                                            this.state.Materialselecionado[0]
                                              .unidade_principal.tipo ===
                                              "Peso"
                                              ? this.state.Volume
                                              : this.state.Peso,
                                          quantidade:
                                            this.state.Materialselecionado[0]
                                              .unidade_principal.tipo !==
                                              "Peso"
                                              ? this.state.Volume
                                              : this.state.Peso,

                                          unidade_quantidade:
                                            this.state.Materialselecionado[0]
                                              .unidade_principal.nome,
                                          unidade_equivalencia:
                                            this.state.Materialselecionado[0]
                                              .unidade_secundaria.nome,
                                          unidade_quantidade_id:
                                            this.state.Materialselecionado[0]
                                              .unidade_principal.id,
                                          unidade_equivalencia_id:
                                            this.state.Materialselecionado[0]
                                              .unidade_secundaria.id,
                                          has_register:
                                            this.state.hasRegister,
                                        };
                                        materiais.push(novo_material);
                                        console.log(
                                          "material completo",
                                          this.state.Materialselecionado[0]
                                        );
                                        let valor =
                                          this.state.ValorFinanceiro;
                                        console.log(
                                          this.state.MateriaisInseridos
                                        );
                                        let saldo;
                                        let receita;
                                        let despesa;
                                        console.log(parseFloat(valor));
                                        console.log(
                                          parseFloat(this.state.Saldo)
                                        );
                                        console.log(
                                          parseFloat(this.state.ReceitaTotal)
                                        );
                                        console.log(this.state.Movimentacao);
                                        if (
                                          this.state.Movimentacao == "Receita"
                                        ) {
                                          saldo =
                                            parseFloat(
                                              parseFloat(
                                                this.state.Saldo
                                              ).toFixed(2)
                                            ) +
                                            parseFloat(
                                              parseFloat(valor).toFixed(2)
                                            );
                                          receita =
                                            parseFloat(
                                              parseFloat(
                                                this.state.ReceitaTotal
                                              ).toFixed(2)
                                            ) +
                                            parseFloat(
                                              parseFloat(valor).toFixed(2)
                                            );
                                          despesa = parseFloat(
                                            parseFloat(
                                              this.state.DespesaTotal
                                            ).toFixed(2)
                                          );
                                        } else if (
                                          this.state.Movimentacao == "Despesa"
                                        ) {
                                          despesa =
                                            parseFloat(
                                              parseFloat(
                                                this.state.DespesaTotal
                                              ).toFixed(2)
                                            ) +
                                            parseFloat(
                                              parseFloat(valor).toFixed(2)
                                            );
                                          saldo =
                                            parseFloat(
                                              parseFloat(
                                                this.state.Saldo
                                              ).toFixed(2)
                                            ) -
                                            parseFloat(
                                              parseFloat(valor).toFixed(2)
                                            );
                                          receita = parseFloat(
                                            parseFloat(
                                              this.state.ReceitaTotal
                                            ).toFixed(2)
                                          );
                                        } else {
                                          despesa = parseFloat(
                                            parseFloat(
                                              this.state.DespesaTotal
                                            ).toFixed(2)
                                          );
                                          saldo = parseFloat(
                                            parseFloat(
                                              this.state.Saldo
                                            ).toFixed(2)
                                          );
                                          receita = parseFloat(
                                            parseFloat(
                                              this.state.ReceitaTotal
                                            ).toFixed(2)
                                          );
                                        }
                                        console.log(saldo);
                                        console.log(receita);
                                        console.log(despesa);
                                        this.setState({
                                          required: true,
                                          MateriaisInseridos: materiais,
                                          Materialselecionado: {},
                                          MaterialId: "",
                                          Volume: "",
                                          Peso: "",
                                          Movimentacao: "",
                                          Outros: false,
                                          ValorFinanceiro: "",
                                          DestinacaoId: "",
                                          Tratamento: "",
                                          PesoTotal:
                                            parseFloat(this.state.PesoTotal) +
                                            parseFloat(this.state.Peso_kg),
                                          VolumeTotal:
                                            parseFloat(
                                              this.state.VolumeTotal
                                            ) +
                                            parseFloat(this.state.Volume_lt),
                                          ReceitaTotal: receita
                                            .toFixed(2)
                                            .toString(),
                                          DespesaTotal: despesa
                                            .toFixed(2)
                                            .toString(),
                                          Saldo: saldo.toFixed(2).toString(),
                                        });
                                      }
                                    }}
                                  >
                                    Inserir
                                  </button>
                                </div>
                              </>
                            )}
                            {/* Acaba aqui */}
                          </>
                        )}
                      </>
                    )}
                    <hr />

                    {this.state.MateriaisInseridos.length > 0 && (
                      <div className="form-group row">
                        <label
                          className="col-sm-12 col-form-label"
                          style={{ color: "black" }}
                        >
                          Materiais selecionados:
                        </label>

                        <table
                          id="tabela"
                          className="table table-striped table-hover"
                        >
                          <thead>
                            <tr>
                              <th>Material</th>
                              <th>Peso</th>
                              <th>Volume</th>
                              <th data-priority="3">Excluir</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.MateriaisInseridos.map((item, id) => (
                              <tr key={id}>
                                <td>{item.nome}</td>
                                <td>
                                  {maskNumberBr(item.peso + "")}{" "}
                                  {item.unidade_peso}
                                </td>
                                <td>
                                  {maskNumberBr(item.volume + "")}{" "}
                                  {item.unidade_volume}
                                </td>
                                <td>
                                  <button
                                    className="btn btn-danger btn-outline btn-circle m-r-5"
                                    style={{
                                      background: "white",
                                      border: "0px solid red",
                                    }}
                                  >
                                    <FaTrash
                                      color="red"
                                      onClick={() => {
                                        let materiais_inseridos =
                                          this.state.MateriaisInseridos.filter(
                                            (item2) =>
                                              item2.residuo_rejeito_id !=
                                              item.residuo_rejeito_id
                                          );
                                        let receita;
                                        let despesa;
                                        let saldo;
                                        console.log(
                                          parseFloat(
                                            parseFloat(
                                              this.state.ReceitaTotal
                                            ).toFixed(2)
                                          )
                                        );
                                        console.log(
                                          parseFloat(
                                            parseFloat(item.valor).toFixed(2)
                                          )
                                        );
                                        if (item.movimentacao == "Receita") {
                                          receita =
                                            parseFloat(
                                              parseFloat(
                                                this.state.ReceitaTotal
                                              ).toFixed(2)
                                            ) -
                                            parseFloat(
                                              parseFloat(item.valor).toFixed(
                                                2
                                              )
                                            );
                                          despesa = parseFloat(
                                            parseFloat(
                                              this.state.DespesaTotal
                                            ).toFixed(2)
                                          );
                                          saldo =
                                            parseFloat(
                                              parseFloat(
                                                this.state.Saldo
                                              ).toFixed(2)
                                            ) -
                                            parseFloat(
                                              parseFloat(item.valor).toFixed(
                                                2
                                              )
                                            );
                                        } else if (
                                          item.movimentacao == "Despesa"
                                        ) {
                                          receita = parseFloat(
                                            parseFloat(
                                              this.state.ReceitaTotal
                                            ).toFixed(2)
                                          );
                                          despesa =
                                            parseFloat(
                                              parseFloat(
                                                this.state.DespesaTotal
                                              ).toFixed(2)
                                            ) -
                                            parseFloat(
                                              parseFloat(item.valor).toFixed(
                                                2
                                              )
                                            );
                                          saldo =
                                            parseFloat(
                                              parseFloat(
                                                this.state.Saldo
                                              ).toFixed(2)
                                            ) +
                                            parseFloat(
                                              parseFloat(item.valor).toFixed(
                                                2
                                              )
                                            );
                                        } else {
                                          despesa = parseFloat(
                                            parseFloat(
                                              this.state.DespesaTotal
                                            ).toFixed(2)
                                          );
                                          saldo = parseFloat(
                                            parseFloat(
                                              this.state.Saldo
                                            ).toFixed(2)
                                          );
                                          receita = parseFloat(
                                            parseFloat(
                                              this.state.ReceitaTotal
                                            ).toFixed(2)
                                          );
                                        }

                                        this.setState({
                                          MateriaisInseridos:
                                            materiais_inseridos,
                                          PesoTotal:
                                            parseFloat(this.state.PesoTotal) -
                                            parseFloat(item.peso_kg),
                                          VolumeTotal:
                                            parseFloat(
                                              this.state.VolumeTotal
                                            ) - parseFloat(item.volume_lt),
                                          ReceitaTotal: parseFloat(receita)
                                            .toFixed(2)
                                            .toString(),
                                          DespesaTotal: parseFloat(despesa)
                                            .toFixed(2)
                                            .toString(),
                                          Saldo: parseFloat(saldo)
                                            .toFixed(2)
                                            .toString(),
                                        });
                                      }}
                                    />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}

                    <label
                      className="col-sm-12 col-form-label"
                      style={{ color: "black", fontSize: "1.5rem" }}
                    >
                      Dados do despacho:
                    </label>
                    <div className="form-group row">
                      <div className="col-sm-12">
                        <label className="col-sm-4 col-form-label">
                          Situação:{" "}
                        </label>
                        {this.handleStatusChange()}
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-12">
                        {" "}
                        <label className="col-sm-4 col-form-label">
                          Data da criação:{" "}
                        </label>
                        {this.state.DataAtual.replace(
                          /(\d*)-(\d*)-(\d*).*/,
                          "$3/$2/$1"
                        )}{" "}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        Agendado para:{" "}
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          value={this.state.DataAgendamento}
                          name="DataAgendamento"
                          type="date"
                          style={{ maxWidth: 168 }}
                          onChange={this.handleDateChange}
                        />
                        {this.state.GenerateCollectOpen && <small className="text-secondary">Você poderá escolher os horários após <strong>salvar</strong> a destinação interna e criar a solicitação de coleta na Blueen Open.</small>}
                      </div>
                    </div>

                    {(this.props.blueenOpen && this.state.DestinoSelecionado[0]?.isOpen && !this.state.GenerateCollectOpen) &&
                      <div className="col-12">
                        <button
                          className='btn btn-primary btn-large mt-3'
                          type="button"
                          style={{ minWidth: 168, height: 38 }}
                          onPointerDown={() => this.handleSolicitarColetaOpen()}
                        >
                          Solicitar coleta
                        </button>
                      </div>
                    }
                    {this.state.GenerateCollectOpen && <div className="d-flex align-items-center gap-1 pt-1">
                      <FaRegCheckCircle style={{ color: 'green' }} />
                      <span>Uma solicitação de coleta será gerada na Blueen Open após clicar em <strong>Salvar</strong>.</span>
                    </div>}

                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        Data coleta:{" "}
                      </label>
                      <div className="col-sm-12 d-flex align-items-center">
                        <div
                          className="col-sm-4"
                          style={{ marginRight: "5px" }}
                        >
                          <input
                            className="form-control me-2"
                            value={this.state.DataColeta}
                            name="DataColeta"
                            type="date"
                            style={{ maxWidth: 168 }}
                            onChange={this.handleDateChange}
                          />
                        </div>
                        {this.state.DataColeta && (
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckDefault"
                              value={this.state.genMtr}
                              onChange={this.handleMtrChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexSwitchCheckDefault"
                            >
                              Gerar MTR ao salvar
                            </label>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        Data recebimento:
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          value={this.state.DataRecebido}
                          name="DataRecebido"
                          type="date"
                          style={{ maxWidth: 168 }}
                          min={this.state.DataColeta}
                          onChange={this.handleDateChange}
                        // disabled={this.state.DataRecebido != '' ? true : false}
                        // min={this.state.DataAtual}
                        // max={this.state.DataAgendamento}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-12">
                        <label className="col-sm-4 col-form-label">
                          Peso total:{" "}
                        </label>{" "}
                        {maskNumberBr(this.state.PesoTotal) + ""} Kg
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-12">
                        <label className="col-sm-4 col-form-label">
                          Volume total:{" "}
                        </label>{" "}
                        {maskNumberBr(this.state.VolumeTotal + "")} L
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-12">
                        <label className="col-sm-4 col-form-label">
                          Receita:{" "}
                        </label>{" "}
                        R$ {this.state.ReceitaTotal.replace(".", ",")}
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-12">
                        <label className="col-sm-4 col-form-label">
                          Despesas:{" "}
                        </label>{" "}
                        R$ {this.state.DespesaTotal.replace(".", ",")}
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-12">
                        <label className="col-sm-4 col-form-label">
                          Saldo:{" "}
                        </label>{" "}
                        R$ {this.state.Saldo.replace(".", ",")}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        Transportadora
                      </label>
                      <div className="input-group">
                        <select
                          value={this.state.TransportadoraId}
                          className="form-control"
                          onChange={(e) => {
                            this.setState({
                              TransportadoraId: e.target.value,
                              VeiculoId: "",
                              MotoristaId: "",
                            });
                          }}
                        >
                          <option value={""}>Selecione uma opção</option>
                          <option value={"proprio"}>Carro Próprio</option>
                          {this.state.Transportadoras.map((item, id) => (
                            <option key={id} value={item.id}>
                              {item.nome_fantasia}
                            </option>
                          ))}
                        </select>
                        <TransportadoraModal updateState={this.updateState} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        Veículo
                      </label>
                      <div className="input-group">
                        <select
                          value={this.state.VeiculoId}
                          className="form-control"
                          onChange={(e) => {
                            this.proprio = false;
                            for (
                              let i = 0;
                              i < this.state.Veiculos.length;
                              i++
                            ) {
                              if (
                                this.state.Veiculos[i].id === e.target.value
                              ) {
                                if (
                                  this.state.Veiculos[i].transportadora_id ==
                                  null
                                ) {
                                  this.proprio = true;
                                  break;
                                }
                              }
                            }
                            this.setState({
                              VeiculoId: e.target.value,
                              MotoristaId: "",
                            });
                          }}
                        >
                          <option value={""}>Selecione uma opção</option>

                          {this.state.Veiculos.filter((item, id) => {
                            if (
                              item.transportadora_id == null &&
                              this.state.TransportadoraId === "proprio"
                            )
                              return true;
                            else if (
                              item.transportadora_id ===
                              this.state.TransportadoraId
                            )
                              return true;
                            else return false;
                          }).map((item, id) => (
                            <option key={id} value={item.id}>
                              {item.modelo.nome}
                            </option>
                          ))}
                        </select>
                        <VeiculoModal
                          updateState={this.updateState}
                          transportadoraId={this.state.TransportadoraId}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        Motorista
                      </label>
                      <div className="input-group">
                        <select
                          value={this.state.MotoristaId}
                          className="form-control"
                          onChange={(e) => {
                            this.setState({ MotoristaId: e.target.value });
                          }}
                        >
                          <option value={""}>Selecione uma opção</option>
                          {this.state.VeiculoId != "" &&
                            this.state.Motoristas.filter((item, id) => {
                              if (this.proprio === true) {
                                if (
                                  item.veiculo_id === this.state.VeiculoId
                                ) {
                                  return true;
                                } else {
                                  return false;
                                }
                              } else {
                                if (
                                  item.transportadora_id ===
                                  this.state.TransportadoraId
                                )
                                  return true;
                                else return false;
                              }
                              return false;
                            }).map((item, id) => (
                              <option key={id} value={item.id}>
                                {item.nome}
                              </option>
                            ))}
                        </select>
                        <MotoristaModal updateState={this.updateState} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        Observações{" "}
                      </label>
                      <div className="col-sm-12">
                        <textarea
                          className="form-control"
                          placeholder="Observações"
                          value={this.state.Descricao}
                          type="text"
                          onChange={(e) => {
                            this.setState({ Descricao: e.target.value });
                          }}
                        />
                      </div>
                    </div>
                    <hr />
                    <label
                      className="col-sm-12 col-form-label"
                      style={{ color: "black", fontSize: "1.5rem" }}
                    >
                      Dados fiscais
                    </label>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="check-fiscal-data"
                        checked={this.state.pendenciaFiscal}
                        onChange={(e) =>
                          this.setState({ pendenciaFiscal: e.target.checked })
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="check-fiscal-data"
                      >
                        Não informar dados fiscais no momento
                      </label>
                    </div>
                    {!this.state.pendenciaFiscal && (
                      <div id="fiscal">
                        <div className="form-group row">
                          <label className="col-sm-12 col-form-label">
                            Chave da NF-e
                          </label>
                          <div className="col-sm-12">
                            <input
                              className="form-control"
                              placeholder="Chave de acesso da nota fiscal"
                              value={this.state.ChaveNota}
                              type="text"
                              onChange={(e) => {
                                this.setState({ ChaveNota: e.target.value });
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group  row">
                          <div className=" col-sm-12">
                            <div className="row">
                              <div className="col-md-8 col-12">
                                <label className="col-sm-12 col-form-label">
                                  Número da NF-e{" "}
                                </label>
                                <div className="col-sm-12">
                                  <input
                                    className="form-control"
                                    placeholder="Número da nota fiscal"
                                    type="text"
                                    onChange={(e) =>
                                      this.setState({
                                        NumeroNota: e.target.value,
                                      })
                                    }
                                    value={this.state.NumeroNota}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4 col-12">
                                <label className="col-sm-12 col-form-label">
                                  Série da NF-e{" "}
                                </label>
                                <div className="col-sm-12">
                                  <input
                                    className="form-control"
                                    placeholder="Série da nota fiscal"
                                    type="text"
                                    onChange={(e) =>
                                      this.setState({
                                        SerieNota: e.target.value,
                                      })
                                    }
                                    value={this.state.SerieNota}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <FileInput
                          label="Clique aqui para adicionar um anexo fiscal"
                          fileLabel="Anexo da NF-e:"
                          acceptedFiles="application/pdf,application/vnd.ms-excel"
                          callback={(file) =>
                            this.setState({ AnexoNota: file })
                          }
                          filePath={this.state.AnexoNota}
                        />
                      </div>
                    )}
                  </div>
                </div>

                {this.state.loading_save == false && (
                  <div className="row mt-3">
                    <div
                      className="col-12 col-sm-4 d-flex justify-content-center justify-content-sm-start"
                      style={{ marginBottom: "0.5rem" }}
                    >
                      <Link to="/destinacao_interna">
                        {" "}
                        <span className="btn btn-secondary btn-lg waves-effect waves-light">
                          <MdReply /> Voltar
                        </span>
                      </Link>
                    </div>
                    <div
                      className="col-12 col-sm-8 d-flex justify-content-center justify-content-sm-end"
                      style={{ marginBottom: "0.5rem" }}
                    >
                      <button
                        type="button"
                        onClick={() => this.salvar()}
                        className="btn btn-success btn-lg waves-effect waves-light"
                        style={{
                          backgorundColor: "#f2f2f2",
                          marginLeft: "5px",
                        }}
                      >
                        Salvar destinação
                      </button>
                    </div>
                  </div>
                )}
                {this.state.loading_save == true && (
                  <div className="row">
                    {" "}
                    <div
                      className="col-12 d-flex justify-content-center align-items-center"
                      style={{ marginTop: "2rem" }}
                    >
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      ></div>
                    </div>
                  </div>
                )}
              </div>
              }

              {this.state.loading_screen == true && (
                <div className="row">
                  {" "}
                  <div
                    className="col-12 d-flex justify-content-center align-items-center"
                    style={{ marginTop: "2rem" }}
                  >
                    <div
                      className="spinner-border text-primary"
                      role="status"
                    ></div>
                  </div>
                </div>
              )}

              <SweetAlert
                warning
                title={"Atenção"}
                onConfirm={() => {
                  this.setState({ show_warning: false });
                }}
                show={this.state.show_warning}
                confirmBtnText="     OK     "
                confirmBtnBsStyle="success"
              >
                Para gerar o MTR você deve preencher todos os campos que são
                obrigatórios.
              </SweetAlert>

              <SweetAlert
                warning
                title={"Atenção"}
                onConfirm={() => { this.setState({ Materialselecionado: {} }, () => this.salvar()) }}
                onCancel={() => { this.setState({ show_warning_inserir: false, loading_save: false }); }}
                show={this.state.show_warning_inserir}
                confirmBtnText='Sim, desejo salvar '
                cancelBtnText='Cancelar'
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                showCancel={true}
              >
                Existe um material selecionado que não foi inserido, deseja salvar mesmo assim ?
              </SweetAlert>

              <SweetAlert
                warning
                title={"Atenção"}
                onConfirm={() => {
                  this.setState({
                    show_warning_internal_relationship: false,
                    redirect_solicitar_coleta: true
                  })
                }
                }
                onCancel={() => { this.setState({ show_warning_internal_relationship: false }); }}
                show={this.state.show_warning_internal_relationship}
                confirmBtnText='Redirecionar'
                cancelBtnText='Ficar'
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                showCancel={true}
              >
                O destino selecionado é um relacionamento interno. Deseja redirecionar para a tela específica?
              </SweetAlert>
            </div>

          </div>
        </div>
      </div>
    );
  }


}
const mapsStateToProps = (state) => ({
  user: state.AppReducer.user,
  token: state.AppReducer.token,
  blueenOpen: state.AppReducer.permissoes?.blueen_open?.acesso,
});

export default connect(mapsStateToProps, { logout })(CriarDestinacaoInterna);
