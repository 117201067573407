import React, { useCallback, useEffect, useState } from "react";
import { URL } from "../variables";
import { useSelector, useDispatch } from "react-redux";
import Header from "../Header";
import Aside from "../SideBar/Aside";

import "../SideBar/styles/App.scss";
import { login, logout, mudarDados } from "../actions/AppActions";
import { Redirect, Route, Switch, useLocation } from "react-router";

import Unidades from "./Configuracoes/Unidade/Unidades";
import CriarUnidade from "./Configuracoes/Unidade/CriarUnidade";
import EditarUnidade from "./Configuracoes/Unidade/EditarUnidade";
import SelecionarUnidade from "./Configuracoes/Unidade/SelecionarJsUnidades";
import EditarUnidadeSigor from "./Configuracoes/Unidade/EditarUnidadeSigor";

import Classes from "./Configuracoes/Classe/Classes";
import CriarClasse from "./Configuracoes/Classe/CriarClasse";
import EditarClasse from "./Configuracoes/Classe/EditarClasse";
import SelecionarClasse from "./Configuracoes/Classe/SelecionarJsClasse";

import EstadosFisicos from "./Configuracoes/EstadoFisico/EstadosFisicos";
import CriarEstadoFisico from "./Configuracoes/EstadoFisico/CriarEstadoFisico";
import EditarEstadoFisico from "./Configuracoes/EstadoFisico/EditarEstadoFisico";
import SelecionarEstadosFisicos from "./Configuracoes/EstadoFisico/SelecionarJsEstadosFisicos";
import EditarEstadoSigor from "./Configuracoes/EstadoFisico/EditarEstadoSigor";

import Acondicionamento from "./Configuracoes/Acondicionamento/Acondicionamento";
import CriarAcondicionamento from "./Configuracoes/Acondicionamento/CriarAcondicionamento";
import EditarAcondicionamento from "./Configuracoes/Acondicionamento/EditarAcondicionamento";
import SelecionarAcondicionamento from "./Configuracoes/Acondicionamento/SelecionarJsAcondicionamento";
import EditarAcondicionamentoSigor from "./Configuracoes/Acondicionamento/EditarAcondicionamentoSigor";

import Tratamento from "./Configuracoes/Tratamento/Tratamento";
import CriarTratamento from "./Configuracoes/Tratamento/CriarTratamento";
import EditarTratamento from "./Configuracoes/Tratamento/EditarTratamento";
import SelecionarTratamento from "./Configuracoes/Tratamento/SelecionarJsTratamento";
import EditarTratamentoSigor from "./Configuracoes/Tratamento/EditarTratamentoSigor";

import ResiduoRejeito from "./Cadastros/ResiduosRejeitos/ResiduoRejeito";
import CriarRR from "./Cadastros/ResiduosRejeitos/CriarRR";
import EditarRR from "./Cadastros/ResiduosRejeitos/EditarRR";
import SelecionarResiduoRejeito from "./Cadastros/ResiduosRejeitos/SelecionarJsRR";
import EditarRRSigor from "./Cadastros/ResiduosRejeitos/EditarRRSigor";

import DestinacaoDisposicao from "./Configuracoes/DestinacaoDisposicao/DestinacaoDisposicao";
import CriarDestinacaoDisposicao from "./Configuracoes/DestinacaoDisposicao/CriarDestinacaoDisposicao";
import EditarDestinacaoDisposicao from "./Configuracoes/DestinacaoDisposicao/EditarDestinacaoDisposicao";
import ArmazenamentoTemporario from "./Configuracoes/ArmazenamentoTemporario/ArmazenamentoTemporario";
import CriarArmazenamento from "./Configuracoes/ArmazenamentoTemporario/CriarArmazenamento";
import EditarArmazenamento from "./Configuracoes/ArmazenamentoTemporario/EditarArmazenamento";
import Periculosidade from "./Configuracoes/Periculosidade/Periculosidade";
import CriarPericulosidade from "./Configuracoes/Periculosidade/CriarPericulosidade";
import EditarPericulosidade from "./Configuracoes/Periculosidade/EditarPericulosidade";
import Destinos from "./Cadastros/Destinos/Destinos";
import TiposUsuarios from "./Cadastros/TiposUsuarios/TiposUsuarios";
import CriarTipoUsuario from "./Cadastros/TiposUsuarios/CriarTipoUsuario";
import EditarTipoUsuario from "./Cadastros/TiposUsuarios/EditarTipoUsuario";
import Pessoas from "./Cadastros/Pessoas/Pessoas";
import CriarPessoa from "./Cadastros/Pessoas/CriarPessoa";
import EditarPessoa from "./Cadastros/Pessoas/EditarPessoa";
import CriarDestinos from "./Cadastros/Destinos/CriarDestinos";
import TiposVeiculos from "./Configuracoes/TipoVeiculo/TiposVeiculos";
import CriarTipoVeiculo from "./Configuracoes/TipoVeiculo/CriarTipoVeiculo";
import EditarTipoVeiculo from "./Configuracoes/TipoVeiculo/EditarTipoVeiculo";
import MarcasVeiculos from "./Configuracoes/MarcasVeiculos/MarcasVeiculos";
import CriarMarca from "./Configuracoes/MarcasVeiculos/CriarMarca";
import EditarMarca from "./Configuracoes/MarcasVeiculos/EditarMarca";
import ModelosVeiculos from "./Configuracoes/ModelosVeiculos/ModelosVeiculos";
import CriarModelo from "./Configuracoes/ModelosVeiculos/CriarModelo";
import EditarModelo from "./Configuracoes/ModelosVeiculos/EditarModelo";
import Veiculos from "./Cadastros/Veiculos/Veiculos";
import CriarVeiculo from "./Cadastros/Veiculos/CriarVeiculo";
import EditarVeiculo from "./Cadastros/Veiculos/EditarVeiculo";
import Transportadoras from "./Cadastros/Transportadoras/Transportadoras";
import CriarTransportadora from "./Cadastros/Transportadoras/CriarTransportadora";
import EditarTransportadora from "./Cadastros/Transportadoras/EditarTransportadora";
import Estoques from "./Cadastros/Estoques/Estoques";
import CriarEstoque from "./Cadastros/Estoques/CriarEstoque";
import EditarEstoque from "./Cadastros/Estoques/EditarEstoque";
import PontosColeta from "./Cadastros/PontosColeta/PontosColeta";
import CriarPontoColeta from "./Cadastros/PontosColeta/CriarPontoColeta";
import EditarPontoColeta from "./Cadastros/PontosColeta/EditarPontoColeta";
import EditarDestinos from "./Cadastros/Destinos/EditarDestino";
import RegistroEntrada from "./ColetaTrasporte/RegistroEntradas/RegistroEntrada";
import Conservacao from "./Configuracoes/Conservacao/Conservacao";
import CriarConservacao from "./Configuracoes/Conservacao/CriarConservacao";
import EditarConservacao from "./Configuracoes/Conservacao/EditarConservacao";
import CriarEntradaColeta from "./ColetaTrasporte/RegistroEntradas/CriarEntrada";
import EditarEntrada from "./ColetaTrasporte/RegistroEntradas/EditarEntrada";
import EstoqueColetaTransporte from "./ColetaTrasporte/Estoque/Estoque";
import RegistrosGestaoInterna from "./GestaoInterna/RegistrosEntrada/RegistrosGestaoInterna";
import CriarRegistro from "./GestaoInterna/RegistrosEntrada/CriarRegistro";
import EditarRegistro from "./GestaoInterna/RegistrosEntrada/EditarRegistro";
import EstoqueGestaoInterna from "./GestaoInterna/Estoque/Estoque";
import Dashboard from "./ColetaTrasporte/Dashboard/Dashboard";

import Dashboard2 from "./GestaoInterna/Dashboard/Dashboard";
import SolicitarColeta from "./ColetaTrasporte/SolicitarColeta/SolicitarColeta";
import Mapa from "./ColetaTrasporte/Mapa/Mapa";
import CriarSolicitacao from "./ColetaTrasporte/SolicitarColeta/CriarSolicitacao";
import EditarSolicitacao from "./ColetaTrasporte/SolicitarColeta/EditarSolicitacao";
import EditarEmpresa from "./Cadastros/Empresa/EditarEmpresa";

import Auditorias from "./GestaoAcesso/Auditorias/Auditorias";
import EditarAuditoria from "./GestaoAcesso/Auditorias/EditarAuditoria";
import GestaoAcessoAdmin from "./SuperAdmin/GestaoDeAcesso/GestaoDeAcesso";
import LogoAdmin from "./SuperAdmin/MudarLogo/CriarLogo";

import Destinacao from "./ColetaTrasporte/Destinacao/Destinacao";
import CriarDestinacao from "./ColetaTrasporte/Destinacao/CriarDestinacao";
import Motorista from "./Cadastros/Motorista/Motorista";
import CriarMotorista from "./Cadastros/Motorista/CriarMotorista";
import EditarMotorista from "./Cadastros/Motorista/EditarMotorista";
import EditarDestinacao from "./ColetaTrasporte/Destinacao/EditarDestinacao";
import ViewSolicitacaoColeta from "./ColetaTrasporte/SolicitarColeta/ViewSolicitacaoColeta";
import ViewSolicitacaoColetaFinalizada from "./ColetaTrasporte/SolicitarColeta/ViewSolicitacaoColetaFinalizada";
import ViewDestinacaoColetaFinalizada from "./ColetaTrasporte/Destinacao/ViewDestinacaoColetaFinalizada";
import ViewDestinacaoColeta from "./ColetaTrasporte/Destinacao/ViewDestinacaoColeta";
import Despacho from "./ColetaTrasporte/Despacho/Despacho";
import CriarDespacho from "./ColetaTrasporte/Despacho/CriarDespacho";
import EditarDespacho from "./ColetaTrasporte/Despacho/EditarDespacho";
import ViewDespacho from "./ColetaTrasporte/Despacho/ViewDespacho";
import ViewDespachoFinalizada from "./ColetaTrasporte/Despacho/ViewDespachoFinalizada";
import DespachosAbertos from "./ColetaTrasporte/ReceberColeta/DespachosAbertos";
import ReceberColeta from "./ColetaTrasporte/ReceberColeta/ReceberColeta";
import DestinacaoInterna from "./GestaoInterna/DestinacaoInterna/DestinacaoInterna";
import CriarDestinacaoInterna from "./GestaoInterna/DestinacaoInterna/CriarDestinacaoInterna";
import EditarDestinacaoInterna from "./GestaoInterna/DestinacaoInterna/EditarDestinacaoInterna";
import ViewDestinacaoInterna from "./GestaoInterna/DestinacaoInterna/ViewDestinacaoInterna";
import ViewDestinacaoInternaFinalizada from "./GestaoInterna/DestinacaoInterna/ViewDestinacaoInternaFinalizada";
import SolicitarColetaSimplificada from "./ColetaTrasporte/SolicitarColetaSimplificada/SolicitarColetaSimplificada";
import ViewPessoa from "./Cadastros/Pessoas/ViewPessoa";
import ChatGeral from "./Comunicacao/ChatGeral";
import RelatoriosGestaoInterna from "./GestaoInterna/Relatorios/RelatoriosGestaoInterna";
import Pesagens from "./Triagem/Pesagem/Pesagens";
import Relatorio from "./Triagem/Relatorio/Relatorio";
import Separacoes from "./Triagem/Separacao/Separacoes";
import ViewSeparacao from "./Triagem/Separacao/ViewSeparacao";
import EditarSeparacao from "./Triagem/Separacao/EditarSeparacao";
import EstoqueTriagem from "./GestaoInterna/EstoqueTriagem/EstoqueTriagem";
import Coletas from "./ColetaTrasporte/ReceberColeta/Coletas";
import ColetaRecebida from "./ColetaTrasporte/ReceberColeta/ColetaRecebida";
import Atualizacoes from "./Configuracoes/Sigor/Atualizacoes";
import Pesagem from "./Triagem/Pesagem/Pesagem";
import Separacao from "./Triagem/Separacao/Separacao";
import EditPesagem from "./Triagem/Pesagem/EditPesagem";
import EmpresaDomain from "./SuperAdmin/Empresa/EmpresaDomain";
import EditSeparacao from "./Triagem/Separacao/EditSeparacao";
import EditRelatorio from "./Triagem/Relatorio/EditRelatorio";
import ViewRelatorio from "./Triagem/Relatorio/ViewRelatorio";
import Mtrs from "./ColetaTrasporte/MTR/Mtrs";
import EditData from "./ColetaTrasporte/Despacho/EditData";
import Perfil from "./GestaoAcesso/Perfil";
import SelecionarJsRelacionamentosExternos from "./GestaoInterna/RelacionamentosBlueenOpen/SelecionarJsRelacionamentosExternos";
import ViewRelacionado from "./GestaoInterna/RelacionamentosBlueenOpen/ViewRelacionado";
import SelecionarJsHorariosFuncionamento from "./GestaoInterna/HorariosFuncionamento/SelecionarJsHorariosFuncionamento";
import SelecionarJsAgendamentos from "./GestaoInterna/Agendamentos/SelecionarJsAgendamentos";
import ConvidarPessoa from "./GestaoInterna/RelacionamentosBlueenOpen/ConvidarPessoa/ConvidarPessoa";
import Notificacoes from "../Notificacoes/Notificacoes";
import ViewDestinacaoInternaPendenciaFiscal from "./GestaoInterna/DestinacaoInterna/ViewDestinacaoInternaPendenciaFiscal";
import CriarCompliance from "./Cadastros/Compliance/CriarCompliance";
import EditarCompliance from "./Cadastros/Compliance/EditarCompliance";
import SelecionarDestinos from "./Cadastros/Destinos/SelecionarDestinos";
import VerCompliance from "./Cadastros/Compliance/VerCompliance";
import SelecionarPontosColeta from "./Cadastros/PontosColeta/SelecionarPontosColeta";
import SelecionarTransportadoras from "./Cadastros/Transportadoras/SelecionarTransportadoras";
import SelecionarVeiculos from "./Cadastros/Veiculos/SelecionarVeiculos";
import SelecionarMotoristas from "./Cadastros/Motorista/SelecionarMotoristas";
import SelecionarJsRelatoriosInterna from "./RelatoriosIndicadores/GestaoInterna/SelecionarJsRelatoriosInterna";
import SelecionarJsRelatoriosColeta from "./RelatoriosIndicadores/ColetaTransporte/SelecionarJsRelatoriosColeta";
import VerRelatorioRI from "./RelatoriosIndicadores/VerRelatorioRI";
import ListarCompliances from "./Cadastros/Compliance/ListarCompliances";
import { compliances } from "../Configs/Compliance";
import AcompanharEtapas from "./ColetaTrasporte/AcompanharEtapas/AcompanharEtapas";
import ViewDetails from "./ColetaTrasporte/AcompanharEtapas/ViewDetails";
import Rastreabilidade from "./ColetaTrasporte/Blockchain/Rastreabilidade";
import Kanban from "./ColetaTrasporte/Kanban/Kanban";
import ViewDestino from "./Cadastros/Destinos/ViewDestino";
import SelecionarJsRelacionamentosInternos from "./Relacionamentos/Internos/SelecionarJSRelacionamentosInternos";
import ConvidarOrganizacao from "./Relacionamentos/Internos/ConvidarOrganizacao/ConvidarOrganizacaoForm";
import AceitarConvite from "./Relacionamentos/Internos/ConvidarOrganizacao/AceitarConviteForm";
import ViewRelacionamentoInterno from "./Relacionamentos/Internos/ViewRelacionamentoInterno";
import ViewPontoColeta from "./Cadastros/PontosColeta/ViewPontoColeta";
import DestinacaoInternaColeta from "./Relacionamentos/Internos/Coleta/DestinacaoInternaColeta";
import AcompanharDestinacaoColeta from "./Relacionamentos/Internos/Coleta/AcompanharDestinacaoColeta";
import AcompanharSolicitacaoColeta from "./ColetaTrasporte/SolicitarColeta/RelacionamentosInternos/AcompanharColeta";
import RelatoriosColetaTransporte from "./ColetaTrasporte/Relatorios/RelatoriosColetaTransporte";

const OtherRoute = ({ component: Component, path_name, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Redirect to={{ pathname: path_name, state: { from: props.location } }} />
    )}
  />
);

const Home = (props) => {
  const toggled = useSelector((store) => store.AppReducer.toggled);
  const collapsed = useSelector((store) => store.AppReducer.collapsed);
  const token = useSelector((store) => store.AppReducer.token);
  const user = useSelector((store) => store.AppReducer.user);
  const permissions = useSelector((store) => store.AppReducer.permissoes);
  const locationV = useLocation();
  let permissoes = useSelector(store => store.AppReducer.permissoes);

  const dispatch = useDispatch();
  const [loading_home, setLoadingHome] = useState(true);
  const [image, setImage] = useState(true);
  // const [toggled, setToggled] = useState(false);

  const handleCollapsedChange = (checked) => {
    dispatch(mudarDados({ collapsed: checked }));
  };

  const handleImageChange = (checked) => {
    setImage(checked);
  };

  const handleToggleSidebar = (value) => {
    // setToggled(value);
    console.log(value);
    dispatch(mudarDados({ toggled: value }));
  };

  const get_user = useCallback(() => {
    fetch(`${URL}api/auth/user`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          console.log(resp);
          if (resp.message == "Unauthenticated.") {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            dispatch(logout());
            return;
          }
          if (resp.errors != null || resp.error != null) {
            let errors = Object.values(resp.errors);
            let erro = "";
            for (let i = 0; i < errors.length; i++) {
              if (i != errors.length - 1) erro += errors[i] + "\n";
              else erro += errors[i];
            }
            console.log(erro);
            setTimeout(() => {
              get_user();
            }, 5000);
          } else {
            let permissoes = {};
            let permissoes_app = {};
            let permissoes_notificacoes = {};

            if (resp.user.tipo_usuario == undefined) {
              localStorage.removeItem("token");
              localStorage.removeItem("user");
              dispatch(logout());
              return;
            }
            for (let i = 0; i < resp.user.tipo_usuario.permissoes.length; i++) {
              permissoes[resp.user.tipo_usuario.permissoes[i].modulo] =
                resp.user.tipo_usuario.permissoes[i];
            }
            for (
              let i = 0;
              i < resp.user.tipo_usuario.permissoes_app.length;
              i++
            ) {
              permissoes_app[resp.user.tipo_usuario.permissoes_app[i].modulo] =
                resp.user.tipo_usuario.permissoes_app[i];
            }
            for (
              let i = 0;
              i < resp.user.tipo_usuario?.permissoes_notificacoes?.length;
              i++
            ) {
              permissoes_notificacoes[
                resp.user.tipo_usuario.permissoes_notificacoes[i].modulo
              ] = resp.user.tipo_usuario.permissoes_notificacoes[i];
            }

            resp.user.tipo_usuario.permissoes = permissoes;
            resp.user.tipo_usuario.permissoes_app = permissoes_app;
            resp.user.tipo_usuario.permissoes_notificacoes =
              permissoes_notificacoes;

            localStorage.setItem("user", JSON.stringify(resp.user));
            dispatch(login({ token: token, user: resp.user }));
            dispatch(mudarDados({ permissoes: resp.permissoes }));

            setLoadingHome(false);
          }
        } catch (err) {
          setTimeout(() => {
            get_user();
          }, 5000);
          console.log(err);
        }
      })
      .catch((err) => {
        setTimeout(() => {
          get_user();
        }, 5000);
      });
  }, [token]);

  useEffect(() => {
    const event = (e) => {
      if (window.innerWidth <= 768) {
        dispatch(mudarDados({ toggled: false, collapsed: false }));
      } else {
        dispatch(mudarDados({ toggled: true, collapsed: false }));
      }
    };
    window.addEventListener("resize", event);
    if (window.innerWidth <= 768) {
      dispatch(mudarDados({ toggled: false, collapsed: false }));
    } else {
      dispatch(mudarDados({ toggled: true, collapsed: false }));
    }
    get_user();

    return () => {
      window.removeEventListener("resize", event);

      // Anything in here is fired on component unmount.
    };
  }, []);

  const marginLeft =
    toggled == false || window.innerWidth <= 768
      ? 0
      : collapsed == false
        ? 270
        : 80;

  const checar_tipo_usuario = useCallback(
    (parameters) => {
      let permission = false;
      for (let i = 0; i < parameters.length; i++) {
        let tipo = parameters[i].split(":")[0];
        let tipo_dado = parameters[i].split(":")[1];
        if (
          user.tipo_usuario.permissoes[tipo] != undefined &&
          user.tipo_usuario.permissoes[tipo][
          tipo_dado == undefined ? "leitura" : tipo_dado
          ] == true
        ) {
          permission = true;
        }
      }
      if (parameters == "comunicacao:leitura") {
        console.log(permission);
      }
      return permission;
    },
    [user]
  );

  const checar_tipo_usuario_has_access = useCallback(
    (parameters) => {
      let permission = false;
      for (let i = 0; i < parameters.length; i++) {
        let tipo = parameters[i].split(":")[0];
        let tipo_dado = parameters[i].split(":")[1];
        if (
          user.tipo_usuario.permissoes_notificacoes[tipo] != undefined &&
          user.tipo_usuario.permissoes_notificacoes[tipo][
          tipo_dado == undefined ? "has_access" : tipo_dado
          ] == true
        ) {
          permission = true;
        }
      }
      return permission;
    },
    [user]
  );

  function handlePaddingTop() {
    let item = locationV.pathname.split("/");

    if (item[1] == "relatorios" && item[3]) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div className={`app ${toggled ? "toggled" : ""}`}>
      <Aside
        image={image}
        collapsed={collapsed}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
      />
      <Header
        image={image}
        toggled={toggled}
        collapsed={collapsed}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
        handleImageChange={handleImageChange}
      />
      <div
        className="content-page"
        style={{
          minHeight: "100vh",
          paddingTop: `${handlePaddingTop() ? "80px" : "150px"}`,
        }}
      >
        <div
          className="content"
          style={{
            marginLeft: marginLeft,
            transition: "all 0.3s ",
            paddingBottom: 100,
            background: "#f8f8fa",
          }}
        >
          {loading_home == false && (
            <div className="container-fluid">
              <Switch>
                {
                  <Route
                    exact
                    path={`${props.match.url}`}
                    render={(props2) => <span></span>}
                  />
                }
                <Route
                  exact
                  path={`${props.match.url}perfil/:id`}
                  render={(props2) => <Perfil {...props2} />}
                />
                {checar_tipo_usuario(["comunicacao:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}chat_geral`}
                    render={(props2) => <ChatGeral {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["realizar_despacho:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}coleta_despacho/:processType/view/:id`}
                    render={(props2) => <ViewDetails {...props2} />}
                  />
                )}
                {/*checar_tipo_usuario(["realizar_despacho:escrita"])*/ true && (
                  <Route
                    exact
                    path={`${props.match.url}rastreabilidade/:processType/:id`}
                    render={(props2) => <Rastreabilidade {...props2} />}
                  />
                )}
                {checar_tipo_usuario_has_access([
                  "destinacaointerna.ativar_notificacoes:has_access",
                ]) && (
                    <Route
                      exact
                      path={`${props.match.url}notificacoes`}
                      render={(props2) => <Notificacoes {...props2} />}
                    />
                  )}
                {checar_tipo_usuario(["separacao:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}separacao`}
                    render={(props2) => <Separacoes {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["separacao:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}separacao/view/:id`}
                    render={(props2) => <ViewSeparacao {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["separacao:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}separacao/:id`}
                    render={(props2) => <Separacao {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["separacao:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}separacao/edit/:id`}
                    render={(props2) => <EditSeparacao {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["separacao:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}separacao/editar/:id`}
                    render={(props2) => <EditarSeparacao {...props2} />}
                  />
                )}

                {checar_tipo_usuario(["pesagem:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}pesagens`}
                    render={(props2) => <Pesagens {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["pesagem:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}pesagem/:id`}
                    render={(props2) => <Pesagem {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["pesagem:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}pesagem/edit/:id`}
                    render={(props2) => <EditPesagem {...props2} />}
                  />
                )}

                {checar_tipo_usuario(["relatorios_sub:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}relatorio`}
                    render={(props2) => <Relatorio {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["relatorios_sub:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}relatorio/edit/:id`}
                    render={(props2) => <EditRelatorio {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["relatorios_sub:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}relatorio/view/:id`}
                    render={(props2) => <ViewRelatorio {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["relatorios_coleta:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}relatorio_coleta_transporte`}
                    render={(props2) => (
                      <RelatoriosColetaTransporte {...props2} />
                    )}
                  />
                )}
                {checar_tipo_usuario(["unidades:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}unidades`}
                    render={(props2) => <SelecionarUnidade {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["unidades:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}unidades/criar`}
                    render={(props2) => <CriarUnidade {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["unidades:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}unidades/editar/:id`}
                    render={(props2) => <EditarUnidade {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["unidades:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}unidades/editarSigor/:id`}
                    render={(props2) => <EditarUnidadeSigor {...props2} />}
                  />
                )}

                {checar_tipo_usuario(["classes:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}classes`}
                    render={(props2) => <SelecionarClasse {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["classes:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}classes/criar`}
                    render={(props2) => <CriarClasse {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["classes:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}classes/editar/:id`}
                    render={(props2) => <EditarClasse {...props2} />}
                  />
                )}

                {checar_tipo_usuario(["estados_fisicos:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}estados`}
                    render={(props2) => (
                      <SelecionarEstadosFisicos {...props2} />
                    )}
                  />
                )}
                {checar_tipo_usuario(["estados_fisicos:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}estados/criar`}
                    render={(props2) => <CriarEstadoFisico {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["estados_fisicos:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}estados/editar/:id`}
                    render={(props2) => <EditarEstadoFisico {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["estados_fisicos:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}estados/editarSigor/:id`}
                    render={(props2) => <EditarEstadoSigor {...props2} />}
                  />
                )}

                {checar_tipo_usuario(["acondicionamentos:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}acondicionamentos`}
                    render={(props2) => (
                      <SelecionarAcondicionamento {...props2} />
                    )}
                  />
                )}
                {checar_tipo_usuario(["acondicionamentos:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}acondicionamentos/criar`}
                    render={(props2) => <CriarAcondicionamento {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["acondicionamentos:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}acondicionamentos/editar/:id`}
                    render={(props2) => <EditarAcondicionamento {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["acondicionamentos:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}acondicionamento/editarSigor/:id`}
                    render={(props2) => (
                      <EditarAcondicionamentoSigor {...props2} />
                    )}
                  />
                )}

                {checar_tipo_usuario(["destinacao_disposicao:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}destinacao_disposicao`}
                    render={(props2) => <DestinacaoDisposicao {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["destinacao_disposicao:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}destinacao_disposicao/criar`}
                    render={(props2) => (
                      <CriarDestinacaoDisposicao {...props2} />
                    )}
                  />
                )}
                {checar_tipo_usuario(["destinacao_disposicao:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}destinacao_disposicao/editar/:id`}
                    render={(props2) => (
                      <EditarDestinacaoDisposicao {...props2} />
                    )}
                  />
                )}

                {checar_tipo_usuario(["armazenamento_temporario:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}armazenamentos`}
                    render={(props2) => <ArmazenamentoTemporario {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["armazenamento_temporario:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}armazenamentos/criar`}
                    render={(props2) => <CriarArmazenamento {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["armazenamento_temporario:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}armazenamentos/editar/:id`}
                    render={(props2) => <EditarArmazenamento {...props2} />}
                  />
                )}

                {checar_tipo_usuario(["tratamento:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}tratamentos`}
                    render={(props2) => <SelecionarTratamento {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["tratamento:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}tratamentos/criar`}
                    render={(props2) => <CriarTratamento {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["tratamento:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}tratamentos/editar/:id`}
                    render={(props2) => <EditarTratamento {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["tratamento:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}tratamentos/editarSigor/:id`}
                    render={(props2) => <EditarTratamentoSigor {...props2} />}
                  />
                )}

                {checar_tipo_usuario(["periculosidade:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}periculosidades`}
                    render={(props2) => <Periculosidade {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["periculosidade:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}periculosidades/criar`}
                    render={(props2) => <CriarPericulosidade {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["periculosidade:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}periculosidades/editar/:id`}
                    render={(props2) => <EditarPericulosidade {...props2} />}
                  />
                )}

                {checar_tipo_usuario(["tipos_veiculos:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}tipos_veiculos`}
                    render={(props2) => <TiposVeiculos {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["tipos_veiculos:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}tipos_veiculos/criar`}
                    render={(props2) => <CriarTipoVeiculo {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["tipos_veiculos:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}tipos_veiculos/editar/:id`}
                    render={(props2) => <EditarTipoVeiculo {...props2} />}
                  />
                )}

                {checar_tipo_usuario(["marcas_veiculos:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}marcas`}
                    render={(props2) => <MarcasVeiculos {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["marcas_veiculos:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}marcas/criar`}
                    render={(props2) => <CriarMarca {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["marcas_veiculos:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}marcas/editar/:id`}
                    render={(props2) => <EditarMarca {...props2} />}
                  />
                )}

                {checar_tipo_usuario(["modelos_veiculos:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}modelos`}
                    render={(props2) => <ModelosVeiculos {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["modelos_veiculos:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}modelos/criar`}
                    render={(props2) => <CriarModelo {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["modelos_veiculos:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}modelos/editar/:id`}
                    render={(props2) => <EditarModelo {...props2} />}
                  />
                )}

                {checar_tipo_usuario(["conservacoes_veiculos:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}conservacoes`}
                    render={(props2) => <Conservacao {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["conservacoes_veiculos:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}conservacoes/criar`}
                    render={(props2) => <CriarConservacao {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["conservacoes_veiculos:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}conservacoes/editar/:id`}
                    render={(props2) => <EditarConservacao {...props2} />}
                  />
                )}

                {checar_tipo_usuario(["residuos_rejeitos:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}residuos_rejeitos`}
                    render={(props2) => (
                      <SelecionarResiduoRejeito {...props2} />
                    )}
                  />
                )}
                {checar_tipo_usuario(["residuos_rejeitos:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}residuos_rejeitos/criar`}
                    render={(props2) => <CriarRR {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["residuos_rejeitos:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}residuos_rejeitos/editar/:id`}
                    render={(props2) => <EditarRR {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["residuos_rejeitos:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}residuos_rejeitos/editarSigor/:id`}
                    render={(props2) => <EditarRRSigor {...props2} />}
                  />
                )}

                {checar_tipo_usuario(["destinos:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}destinos`}
                    render={(props2) => <SelecionarDestinos {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["destinos:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}destinos/criar`}
                    render={(props2) => <CriarDestinos {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["destinos:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}destinos/editar/:id`}
                    render={(props2) => <EditarDestinos {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["destinos:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}destinos/ver/:id`}
                    render={(props2) => <ViewDestino {...props2} />}
                  />
                )}

                {checar_tipo_usuario(["permissoes:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}tipos_usuario`}
                    render={(props2) => <TiposUsuarios {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["permissoes:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}tipos_usuario/criar`}
                    render={(props2) => <CriarTipoUsuario {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["permissoes:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}tipos_usuario/editar/:id`}
                    render={(props2) => <EditarTipoUsuario {...props2} />}
                  />
                )}

                {checar_tipo_usuario(["pessoas:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}pessoas`}
                    render={(props2) => <Pessoas {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["pessoas:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}pessoas/criar`}
                    render={(props2) => <CriarPessoa {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["pessoas:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}pessoas/editar/:id`}
                    render={(props2) => <EditarPessoa {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["pessoas:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}pessoas/view/:id`}
                    render={(props2) => <ViewPessoa {...props2} />}
                  />
                )}

                {checar_tipo_usuario(["veiculos:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}veiculos`}
                    render={(props2) => <SelecionarVeiculos {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["veiculos:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}veiculos/criar`}
                    render={(props2) => <CriarVeiculo {...props2} />}
                  />
                )}

                {checar_tipo_usuario(["veiculos:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}veiculos/editar/:id`}
                    render={(props2) => <EditarVeiculo {...props2} />}
                  />
                )}

                {checar_tipo_usuario(["transportadoras:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}transportadoras`}
                    render={(props2) => (
                      <SelecionarTransportadoras {...props2} />
                    )}
                  />
                )}
                {checar_tipo_usuario(["transportadoras:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}transportadoras/criar`}
                    render={(props2) => <CriarTransportadora {...props2} />}
                  />
                )}

                {checar_tipo_usuario(["transportadoras:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}transportadoras/editar/:id`}
                    render={(props2) => <EditarTransportadora {...props2} />}
                  />
                )}

                {checar_tipo_usuario(["estoques:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}estoques`}
                    render={(props2) => <Estoques {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["estoques:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}estoques/criar`}
                    render={(props2) => <CriarEstoque {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["estoques:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}estoques/editar/:id`}
                    render={(props2) => <EditarEstoque {...props2} />}
                  />
                )}

                {checar_tipo_usuario(["pontos_coleta:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}pontos_coleta`}
                    render={(props2) => <SelecionarPontosColeta {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["pontos_coleta:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}pontos_coleta/criar`}
                    render={(props2) => <CriarPontoColeta {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["pontos_coleta:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}pontos_coleta/ver/:id`}
                    render={(props2) => <ViewPontoColeta {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["pontos_coleta:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}pontos_coleta/editar/:id`}
                    render={(props2) => <EditarPontoColeta {...props2} />}
                  />
                )}

                {checar_tipo_usuario(["registros_entrada_coleta:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}entrada_coleta`}
                    render={(props2) => <RegistroEntrada {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["registros_entrada_coleta:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}entrada_coleta/criar`}
                    render={(props2) => <CriarEntradaColeta {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["registros_entrada_coleta:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}entrada_coleta/editar/:id`}
                    render={(props2) => <EditarEntrada {...props2} />}
                  />
                )}

                {checar_tipo_usuario(["estoques_coleta:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}estoque_coleta_transporte`}
                    render={(props2) => <EstoqueColetaTransporte {...props2} />}
                  />
                )}

                {checar_tipo_usuario(["registros_entrada_interna:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}registro_gestao_interna`}
                    render={(props2) => <RegistrosGestaoInterna {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["registros_entrada_interna:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}registro_gestao_interna/criar`}
                    render={(props2) => <CriarRegistro {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["registros_entrada_interna:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}registro_gestao_interna/editar/:id`}
                    render={(props2) => <EditarRegistro {...props2} />}
                  />
                )}

                {checar_tipo_usuario(["estoques_interna:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}estoque_gestao_interna`}
                    render={(props2) => {
                      if (checar_tipo_usuario(["pesagem:leitura"])) {
                        return <EstoqueTriagem {...props2} />;
                      } else {
                        return <EstoqueGestaoInterna {...props2} />;
                      }
                    }}
                  />
                )}

                {checar_tipo_usuario(["relatorios_coleta:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}coleta_transporte/dashboard`}
                    render={(props2) => <Dashboard {...props2} />}
                  />
                )}

                {checar_tipo_usuario(["relatorios_coleta:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}coleta_transporte/mapa`}
                    render={(props2) => <Mapa {...props2} />}
                  />
                )}

                {checar_tipo_usuario(["relatorios_interna:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}gestao_interna/dashboard`}
                    render={(props2) => <Dashboard2 {...props2} />}
                  />
                )}

                {checar_tipo_usuario(["solicitacoes_coleta:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}solicitar_coleta`}
                    render={(props2) => <SolicitarColeta {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["solicitacoes_coleta:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}solicitar_coleta/criar`}
                    render={(props2) => <CriarSolicitacao {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["solicitacoes_coleta:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}solicitar_coleta/editar/:id`}
                    render={(props2) => <EditarSolicitacao {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["solicitacoes_coleta:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}solicitar_coleta/view/:id`}
                    render={(props2) => <ViewSolicitacaoColeta {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["solicitacoes_coleta:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}solicitar_coleta/follow/:id`}
                    render={(props2) => <AcompanharSolicitacaoColeta {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["solicitacoes_coleta:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}solicitar_coleta_finalizada/view/:id`}
                    render={(props2) => (
                      <ViewSolicitacaoColetaFinalizada {...props2} />
                    )}
                  />
                )}

                {checar_tipo_usuario(["motoristas:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}motoristas`}
                    render={(props2) => <SelecionarMotoristas {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["motoristas:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}motorista/criar`}
                    render={(props2) => <CriarMotorista {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["motoristas:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}motorista/editar/:id`}
                    render={(props2) => <EditarMotorista {...props2} />}
                  />
                )}

                {checar_tipo_usuario(["destinacao:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}destinacao/criar`}
                    render={(props2) => <CriarDestinacao {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["destinacao:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}destinacao/editar/:type/:id`}
                    render={(props2) => <EditData {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["destinacao:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}destinacao_finalizada/view/:id`}
                    render={(props2) => (
                      <ViewDestinacaoColetaFinalizada {...props2} />
                    )}
                  />
                )}

                {checar_tipo_usuario(["dados_empresa:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}empresa`}
                    render={(props2) => <EditarEmpresa {...props2} />}
                  />
                )}

                {checar_tipo_usuario(["dados_empresa:escrita"]) &&
                  permissions.sigor?.acesso && (
                    <Route
                      exact
                      path={`${props.match.url}sigor`}
                      render={(props2) => <Atualizacoes {...props2} />}
                    />
                  )}

                {permissions.sigor?.acesso && (
                  <Route
                    exact
                    path={`${props.match.url}mtrs`}
                    render={(props2) => <Mtrs {...props2} />}
                  />
                )}

                <Route
                  exact
                  path={`${props.match.url}:owner/compliance/`}
                  render={(props2) => <CriarCompliance {...props2} />}
                />

                <Route
                  exact
                  path={`${props.match.url}:owner/compliance/editar/:id`}
                  render={(props2) => <EditarCompliance {...props2} />}
                />

                <Route
                  exact
                  path={`${props.match.url}:owner/compliance/ver/:id`}
                  render={(props2) => <VerCompliance {...props2} />}
                />

                {checar_tipo_usuario(["logs:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}logs`}
                    render={(props2) => <Auditorias {...props2} />}
                  />
                )}

                {checar_tipo_usuario(["logs:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}logs/editar/:id`}
                    render={(props2) => <EditarAuditoria {...props2} />}
                  />
                )}

                {checar_tipo_usuario(["destinacao_interna:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}destinacao_interna`}
                    render={(props2) => <DestinacaoInterna {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["destinacao_interna:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}destinacao_interna/criar`}
                    render={(props2) => <CriarDestinacaoInterna {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["destinacao_interna:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}destinacao_interna/editar/:id`}
                    render={(props2) => <EditarDestinacaoInterna {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["destinacao_interna:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}destinacao_interna/view/:id`}
                    render={(props2) => <ViewDestinacaoInterna {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["destinacao_interna:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}destinacao_interna_finalizada/view/:id`}
                    render={(props2) => (
                      <ViewDestinacaoInternaFinalizada {...props2} />
                    )}
                  />
                )}
                {checar_tipo_usuario(["destinacao_interna:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}destinacao_interna_pendente/view/:id`}
                    render={(props2) => (
                      <ViewDestinacaoInternaPendenciaFiscal {...props2} />
                    )}
                  />
                )}

                {checar_tipo_usuario(["destinacao_interna:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}destinacao_interna/solicitar_coleta/:id`}
                    render={(props2) => <DestinacaoInternaColeta {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["destinacao_interna:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}destinacao_interna_coleta/view/:destination_id/:id`}
                    render={(props2) => <AcompanharDestinacaoColeta {...props2} />}
                  />
                )}

                {checar_tipo_usuario(["realizar_despacho:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}acompanhar_etapas`}
                    render={(props2) => <AcompanharEtapas {...props2} />}
                  />
                )}

                {checar_tipo_usuario(["realizar_despacho:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}despacho`}
                    render={(props2) => <Despacho {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["realizar_despacho:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}despacho/criar`}
                    render={(props2) => <CriarDespacho {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["realizar_despacho:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}despacho/editar/:type/:id`}
                    render={(props2) => <EditData {...props2} />}
                  />
                )}

                {checar_tipo_usuario(["realizar_despacho:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}despacho_finalizado/view/:id`}
                    render={(props2) => <ViewDespachoFinalizada {...props2} />}
                  />
                )}

                {checar_tipo_usuario(["receber_coleta:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}despachos_abertos`}
                    render={(props2) => <Coletas {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["receber_coleta:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}despachos_abertos/receber_coleta/:id`}
                    render={(props2) => <ReceberColeta {...props2} />}
                  />
                )}

                {checar_tipo_usuario(["solicitacao_simplificada:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}solicitar_coleta_simplificada`}
                    render={(props2) => (
                      <SolicitarColetaSimplificada {...props2} />
                    )}
                  />
                )}
                {checar_tipo_usuario(["relatorios_interna:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}relatorios_gestao_interna`}
                    render={(props2) => <RelatoriosGestaoInterna {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["relatorios_coleta:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}relatorios/coleta_transporte`}
                    render={(props2) => (
                      <SelecionarJsRelatoriosColeta {...props2} />
                    )}
                  />
                )}
                {checar_tipo_usuario(["relatorios_coleta:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}relatorios/coleta_transporte/:id`}
                    render={(props2) => <VerRelatorioRI {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["relatorios_interna:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}relatorios/gestao_interna`}
                    render={(props2) => (
                      <SelecionarJsRelatoriosInterna {...props2} />
                    )}
                  />
                )}
                {checar_tipo_usuario(["relatorios_interna:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}relatorios/gestao_interna/:id`}
                    render={(props2) => <VerRelatorioRI {...props2} />}
                  />
                )}

                {permissoes['gestao_acesso'] != undefined && permissoes['gestao_acesso'].acesso == true && (
                  <Route
                    exact
                    path={`${props.match.url}modulos_permissoes`}
                    render={(props2) => <GestaoAcessoAdmin {...props2} />}
                  />
                )}
                {user.name === "Super Admin Verdaz" &&
                  user.tipo_usuario.nome === "SuperAdmin" &&
                  user.email === "superadmin@verdaz.com.br" && (
                    <Route
                      exact
                      path={`${props.match.url}mudarIcones`}
                      render={(props2) => <LogoAdmin {...props2} />}
                    />
                  )}
                {user.name === "Super Admin Verdaz" &&
                  user.tipo_usuario.nome === "SuperAdmin" &&
                  user.email === "superadmin@verdaz.com.br" && (
                    <Route
                      exact
                      path={`${props.match.url}empresaDomain`}
                      render={(props2) => <EmpresaDomain {...props2} />}
                    />
                  )}

                <Route
                  exact
                  path={`${props.match.url}kanban`}
                  render={(props2) => <Kanban {...props2} />}
                />

                {compliances.map((item) => (
                  <Route
                    exact
                    key={item.path}
                    path={`${props.match.url}compliances/${item.path}`}
                    render={(props2) => (
                      <ListarCompliances {...props2} {...item} />
                    )}
                  />
                ))}

                {checar_tipo_usuario(["relacionamento_interno:leitura"]) &&
                  permissions?.relacionamentos?.acesso && (
                    <Route
                      exact
                      path={`${props.match.url}relacionamentos_internos`}
                      render={(props2) => (
                        <SelecionarJsRelacionamentosInternos {...props2} />
                      )}
                    />
                  )}
                {checar_tipo_usuario(["relacionamento_interno:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}relacionamentos_internos/convidar`}
                    render={(props2) => <ConvidarOrganizacao {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["relacionamento_interno:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}relacionamentos_internos/aceitar/:id`}
                    render={(props2) => <AceitarConvite {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["relacionamento_interno:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}relacionamentos_internos/view/:id`}
                    render={(props2) => <ViewRelacionamentoInterno {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["relacionamento_externo:leitura"]) &&
                  permissions?.blueen_open.acesso && (
                    <Route
                      exact
                      path={`${props.match.url}relacionamentos_blueen_open`}
                      render={(props2) => (
                        <SelecionarJsRelacionamentosExternos {...props2} />
                      )}
                    />
                  )}
                {checar_tipo_usuario(["relacionamento_externo:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}relacionamentos_blueen_open/convidar`}
                    render={(props2) => <ConvidarPessoa {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["relacionamento_externo:leitura"]) && (
                  <Route
                    exact
                    path={`${props.match.url}relacionamentos_blueen_open/view/:id`}
                    render={(props2) => <ViewRelacionado {...props2} />}
                  />
                )}
                {checar_tipo_usuario(["horario_funcionamento:escrita"]) && (
                  <Route
                    exact
                    path={`${props.match.url}horarios_funcionamento`}
                    render={(props2) => (
                      <SelecionarJsHorariosFuncionamento {...props2} />
                    )}
                  />
                )}

                {checar_tipo_usuario(["agendamento:leitura"]) &&
                  permissions?.blueen_open?.acesso && (
                    <Route
                      exact
                      path={`${props.match.url}agendamentos`}
                      render={(props2) => (
                        <SelecionarJsAgendamentos {...props2} />
                      )}
                    />
                  )}

                <OtherRoute path="*" path_name="/" />
              </Switch>
            </div>
          )}
          {loading_home == true && (
            <div className="row">
              {" "}
              <div
                className="col-12 d-flex justify-content-center align-items-center"
                style={{ marginTop: "2rem" }}
              >
                <div
                  className="spinner-border text-primary"
                  role="status"
                ></div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
