import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { logout } from "../../../../actions/AppActions";
import { connect } from "react-redux";

import { URL, URLopen } from "../../../../variables";

import { MdClose, MdReply } from "react-icons/md";
import { Box, IconButton, Modal } from "@mui/material";
import BlueenOpenModal from "../BlueenOpenModal";
import { toast, ToastContainer } from "react-toastify";
import { BsFilter } from "react-icons/bs";
import Filter from "../../../../Auxiliar/Filter";

class ConvidarExterno extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            loading_screen: false,
            path: "/relacionamentos_blueen_open",
            name: "",
            company_name: "",
            email: "",
            tipo: "",
            loading_save: false,
            msgErro: "",
            open_modal: false,
        };
    }

    handleClose() {
        this.setState({ open_modal: false });
    }

    convidar() {
        this.setState({ loading_save: true });
        toast.loading("Salvando dados...", {
            toastId: "toastpromisse" + this.state.email,
            hideProgressBar: false,
        });
        fetch(`${URLopen}api/auth/invite_external`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                company_name: this.state.company_name,
                name: this.state.name,
                email: this.state.email,
                organization_url: URL,
                organization_user_id: this.props.user.id,
                organization_user_nome: this.props.user.name,
                organization_user_email: this.props.user.email,
            }),
        })
            .then(async (responseLog) => {
                try {
                    let resp = await responseLog.json();
                    if (resp.errors != null || resp.error != null) {
                        let errors = Object.values(resp.errors);
                        let erro = "";
                        for (let i = 0; i < errors.length; i++) {
                            if (i != errors.length - 1) erro += errors[i] + "\n";
                            else erro += errors[i];
                        }
                        console.log(erro);
                        window.scrollTo(500, 0);

                        this.setState({ loading_save: false, msgErro: erro });

                        toast.update("toastpromisse" + this.state.email, {
                            render: "Erro",
                            type: "error",
                            isLoading: false,
                            autoClose: 1000,
                        });
                    } else {
                        toast.update("toastpromisse" + this.state.email, {
                            render: "Convite enviado por e-mail. Sucesso",
                            type: "success",
                            isLoading: false,
                            autoClose: 2000,
                        });
                        setTimeout(() => {
                            this.setState({ loading_save: false, redirect: true });
                        }, 2500);
                    }
                } catch (err) {
                    console.log(err);
                    toast.update("toastpromisse" + this.state.email, {
                        render: "Erro",
                        type: "error",
                        isLoading: false,
                        autoClose: 1000,
                    });
                    this.setState({
                        loading_save: false,
                        msgErro: "Erro ao pegar resposta do servidor",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                toast.update("toastpromisse" + this.state.email, {
                    render: "Erro",
                    type: "error",
                    isLoading: false,
                    autoClose: 1000,
                });
                this.setState({
                    loading_save: false,
                    msgErro:
                        "Erro ao pegar resposta do servidor. Você está conectado a internet?",
                });
            });
    }

    render() {
        return (
            <div className="row">
                {this.state.open_modal && (
                    <div>
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                            <div className="modal-content">
                                <div className="modal-header text-center">
                                    <h4 className="modal-title">Sobre a Blueen Open</h4>
                                    <IconButton
                                        onClick={() => this.setState({ open_modal: false })}
                                        type="button"
                                        data-dismiss="modal"
                                        aria-label="Fechar"
                                    >
                                        <MdClose
                                            titleAccess="Fechar"
                                            color="error"
                                            aria-hidden="true"
                                        >
                                            &times;
                                        </MdClose>
                                    </IconButton>
                                </div>
                                <div className="modal-body mx-3">
                                    <p>teste</p>
                                    <h4 className="modal-title mt-3">Estoque</h4>
                                    <p>teste</p>
                                    <h4 className="modal-title mt-3">Destino</h4>
                                    <p>teste</p>
                                    <h4 className="modal-title mt-3">Transportadora</h4>
                                    <p>teste</p>
                                    <h4 className="modal-title mt-3">Motorista Independente</h4>
                                    <p>teste</p>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn text-light bg-primary"
                                        onClick={() => this.setState({ open_modal: false })}
                                    >
                                        Entendi
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {this.state.redirect == true && <Redirect to={this.state.path} />}
                <div className="col-12">
                    <hr />
                    {this.state.loading_screen == false && (
                        <div>
                            {this.state.msgErro != "" && (
                                <div>
                                    <div
                                        className="alert alert-danger alert-dismissible fade show mb-0"
                                        role="alert"
                                    >
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="alert"
                                            aria-label="Close"
                                            onClick={() => this.setState({ msgErro: "" })}
                                        ></button>

                                        <p style={{ marginBottom: 0, whiteSpace: "pre" }}>
                                            {this.state.msgErro}
                                        </p>
                                    </div>
                                    <br />
                                </div>
                            )}
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group row">
                                        <label className="col-sm-12 col-form-label">
                                            Nome da empresa <b style={{ color: "red" }}>*</b>
                                        </label>
                                        <div className="col-sm-12">
                                            <input
                                                className="form-control"
                                                placeholder="Nome da empresa"
                                                type="text"
                                                onChange={(e) =>
                                                    this.setState({ company_name: e.target.value })
                                                }
                                                value={this.state.commercial_name}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-12 col-form-label">
                                            Nome <b style={{ color: "red" }}>*</b>
                                        </label>
                                        <div className="col-sm-12">
                                            <input
                                                className="form-control"
                                                placeholder="Nome do convidado"
                                                type="text"
                                                onChange={(e) =>
                                                    this.setState({ name: e.target.value })
                                                }
                                                value={this.state.name}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-12 col-form-label">
                                            E-mail <b style={{ color: "red" }}>*</b>
                                        </label>
                                        <div className="col-sm-12">
                                            <input
                                                className="form-control"
                                                placeholder="E-mail da pessoa"
                                                type="email"
                                                onChange={(e) =>
                                                    this.setState({ email: e.target.value })
                                                }
                                                value={this.state.email}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.state.loading_save == false && (
                                <div className="row mt-4">
                                    <div
                                        className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                                        style={{ marginBottom: "0.5rem" }}
                                    >
                                        <Link to="/relacionamentos_blueen_open">
                                            {" "}
                                            <span className="btn btn-secondary btn-lg waves-effect waves-light">
                                                <MdReply /> Voltar
                                            </span>
                                        </Link>
                                    </div>
                                    <div
                                        className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end"
                                        style={{ marginBottom: "0.5rem" }}
                                    >
                                        <button
                                            type="button"
                                            onClick={() => this.convidar()}
                                            disabled={this.state.loading_save}
                                            className="btn btn-success btn-lg waves-effect waves-light"
                                            style={{ backgorundColor: "#f2f2f2" }}
                                        >
                                            Convidar pessoa
                                        </button>
                                    </div>
                                </div>
                            )}
                            {this.state.loading_save == true && (
                                <div className="row">
                                    {" "}
                                    <div
                                        className="col-12 d-flex justify-content-center align-items-center"
                                        style={{ marginTop: "2rem" }}
                                    >
                                        <div
                                            className="spinner-border text-primary"
                                            role="status"
                                        ></div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    {this.state.loading_screen == true && (
                        <div className="row">
                            {" "}
                            <div
                                className="col-12 d-flex justify-content-center align-items-center"
                                style={{ marginTop: "2rem" }}
                            >
                                <div
                                    className="spinner-border text-primary"
                                    role="status"
                                ></div>
                            </div>
                        </div>
                    )}
                    <ToastContainer
                        position="bottom-right"
                        toastStyle={{ border: "1px solid gray", borderRadius: 4 }}
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={true}
                        pauseOnHover
                    />

                </div>
            </div>
        );
    }
}
const mapsStateToProps = (state) => ({
    user: state.AppReducer.user,
    token: state.AppReducer.token,
});

export default connect(mapsStateToProps, { logout })(ConvidarExterno);
